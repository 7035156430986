import { ExtractPropTypes } from 'vue'
import type { SelectProps } from 'ant-design-vue/es/select'
// type SelectorTypes = keyof Reference.SelectorTypes

export const resumeSourceSelectProps = {
  apiParams: {
    type: Object,
    default: () => ({}),
  }
}

export type ResumeSourceSelectProps = Partial<ExtractPropTypes<typeof resumeSourceSelectProps>> & SelectProps