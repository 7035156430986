import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
export function isChinese(str) {
  let filter = /[\u4E00-\u9FA5\uF900-\uFA2D]{1,}/
  if (filter.test(str)) {
    // console.log('存在汉字')
    return true
  } else {
    // console.log('不存在汉字')
    return false
  }
}

export function isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}

export function getLabelByOptions(value, options: any = [], labelField = 'label', valueFidld = 'value'): string {
  if (!Array.isArray(options)) {
    return '--'
  }
  const findItem = options.find(item => item[valueFidld] == value)
  if (findItem) {
    return findItem[labelField]
  }
  return '--'
}
export function getItemByOptions(value, options: any = [], labelField = 'label', valueFidld = 'value') {
  if (!Array.isArray(options)) {
    return null
  }
  const findItem = options.find(item => item[valueFidld] == value)
  if (findItem) {
    return {
      label: findItem[labelField],
      value: findItem[valueFidld],
      color: findItem.color,
    }
  }
  return null
}
export function getAgeByBirthDay(birthday: string):(number | string) {
  if (!birthday || birthday === '0000-00-00') {
    return '--'
  }
  let today = new Date()
  let birthdate = new Date(birthday)
  let age = today.getFullYear() - birthdate.getFullYear()
  if ((today < birthdate || today.getMonth() < birthdate.getMonth()) && age - 1 >= 0) {
    age-- // 如果今天的月份小于生日的月份或者今天的日期小于生日的日期，则年龄需要减1
  }
  return age
}

// 根据dom导出为pdf
export function getPdfByDom(dom: HTMLElement, filename: string) {
  if (!dom) {
    return
  }
  let title = filename
  html2Canvas(dom, {
    allowTaint: true,
    scale: 2
  }).then(canvas => {
    let contentWidth = canvas.width
    let contentHeight = canvas.height
    // 将canvas转为base64图片
    let pageData = canvas.toDataURL('image/jpeg', 1.0)

    // 设置pdf的尺寸，pdf要使用pt单位 已知 1pt/1px = 0.75   pt = (px/scale)* 0.75
    // 2为上面的scale 缩放了2倍
    let pdfX = (contentWidth + 10) / 2 * 0.75
    let pdfY = (contentHeight + 500) / 2 * 0.75 // 500为底部留白

    // 设置内容图片的尺寸，img是pt单位
    let imgX = pdfX
    let imgY = (contentHeight / 2 * 0.75) // 内容图片这里不需要留白的距离

    // 初始化jspdf 第一个参数方向：默认''时为纵向，第二个参数设置pdf内容图片使用的长度单位为pt，第三个参数为PDF的大小，单位是pt
    let PDF = new JsPDF('', 'pt', [pdfX, pdfY])

    // 将内容图片添加到pdf中，因为内容宽高和pdf宽高一样，就只需要一页，位置就是 0,0
    PDF.addImage(pageData, 'jpeg', 0, 0, imgX, imgY)
    //  PDF.save('download.pdf')
    PDF.save(`${title}.pdf`)
  })
}


export function removeEmptyAndUndefined(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  // 创建一个副本以避免修改原始对象
  const copy = Array.isArray(obj) ? [...obj] : { ...obj }

  // 遍历对象或数组的每一个属性或元素
  for (const key in copy) {
    // 递归调用自身处理嵌套对象和数组
    copy[key] = removeEmptyAndUndefined(copy[key])

    // 如果值是空对象或 undefined，删除该属性或元素
    if (isEmptyObject(copy[key]) || copy[key] === undefined) {
      if (Array.isArray(copy)) {
        copy.splice(key, 1)
      } else {
        delete copy[key]
      }
    }
  }

  return copy
}

export function isEmptyObject(obj) {
  return obj && typeof obj === 'object' && !Array.isArray(obj) && Object.keys(obj).length === 0
}