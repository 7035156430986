import { ExtractPropTypes } from 'vue'
import type { SelectProps } from 'ant-design-vue/es/select'
// type SelectorTypes = keyof Reference.SelectorTypes

export const apiSelectorProps = {
  apiParams: {
    type: Object,
    default: () => ({}),
  },
  selector: {
    type: String,
    default: ''
  },
  valueField: {
    type: String,
    default: 'id'
  },
  labelField: {
    type: String,
    default: 'name'
  },
  api: {
    type: Function
  }
}

export type ApiSelectorProps = Partial<ExtractPropTypes<typeof apiSelectorProps>> & SelectProps