import one from '@/assets/images/workbench/1.png'
import two from '@/assets/images/workbench/2.png'
import three from '@/assets/images/workbench/3.png'
import Nzh from 'nzh'
import currency from 'currency.js'
import { get, isArray } from 'lodash-es'
import UserInfoModal from '@/components/UserInfoModal.vue'
import { useAppWithoutStore } from '@/store'
import { isChinese } from '@/utils/utils'
const { getReferenceConstantMap, getReferenceItemsValueMap } = useAppWithoutStore()
const followRealStatusesValueMap = computed(() => getReferenceItemsValueMap('followRealStatuses'))
const followRealStatusesConstantMap = computed(() => getReferenceConstantMap('followRealStatuses'))
const salaryTypeEnumConstantMap = getReferenceConstantMap('salaryTypeEnum')

const nzhCn = Nzh.cn

const imgs = [one, two, three]

export const handleMoney = (text: string, start = '¥', end = '') => {
  const num = parseFloat(text)
  if (isNaN(num)) {
    return '--'
  }
  let unit = ''
  let precision = parseInt(text) < 10000 ? 0 : 2
  if (num > 10000) {
    text = currency(text).divide(10000).value?.toString()
    unit = '万'
  }

  let moneyText = currency(text ?? '', {
    symbol: '',
    separator: ',',
    precision
  }).format()
    .replace(/(\.\d*[1-9])0+$|\.00$/, '$1')

  return `${start} ${moneyText}${unit}${end}`
}

export const ranking = ({ index, width = 33, height = 33 }) => {
  if (index <= 2) {
    return <img src={imgs[index]} style={{
      width: '20px',
      height: '20px',
      marginLeft: '3px'
    }} />
  }
  return `第${nzhCn.encodeS(index + 1)}名`
}

export const getWorkbenchStatusColumn = (title = '状态', dataIndex = 'status'): IGridTable.ColumnProps => (
  {
    title,
    dataIndex,
    customRender: ({ record }) => (<div class={`${get(record, dataIndex) == followRealStatusesConstantMap.value?.ENTRY?.value ? 'text-red-600' : 'text-yellow-500'}`}>
      {followRealStatusesValueMap.value?.[get(record, dataIndex)]?.label}
    </div>)
  }
)

export const getUserColumn = (title = '标题', dataIndex = 'user.name', userIdField = 'user.id', userNameField = 'user.name', rolesField = 'user.roles'): IGridTable.ColumnProps => (
  {
    title,
    dataIndex,
    className: 'py-[0px]',
    customRender: ({ record }) => {
      let userName = get(record, userNameField)
      let widthValue = 60
      if (userName?.length > 2) {
        let len = 0
        for (let i = 0; i < userName?.length; i++) {
          if (isChinese(userName[i])) {
            // 中文20
            len += 20
          } else {
            // 非中文10
            len += 11
          }
        }
        widthValue = len
      }

      return (<div class={'flex items-center cursor-pointer'}>
        <UserInfoModal userId={get(record, userIdField)} userName={userName}>
          {userName ? <span style={{
            boxSizing: 'border-box',
            width: `${widthValue}px`
          }} class={`text-white text-[14px] flex items-center justify-center  rounded ${(get(record, rolesField))?.map(item => item.name)
            ?.includes('顾问') ? 'bg-[#e26a6a]' : 'bg-[#26c281]'}`}>{userName}</span> : ''}
        </UserInfoModal>
      </div>)
    }
  }
)
export let moneyClass = 'text-[#e57a7a] font-bold'
export const getWorkbenchSalaryColumn = (): IGridTable.ColumnProps => (
  {
    title: '薪资',
    customRender: ({ record }) => <div class={moneyClass}>
      <span>{parseFloat(record.annual_salary) ? `${handleMoney(record.annual_salary)}(年薪)` : '--'}</span>
    </div>
  }
)
export const getSalaryColumn = (title = '薪资', dataIndex = 'salary_section'): IGridTable.ColumnProps => (
  {
    title,
    dataIndex,
    customRender: ({ record }) => {
      if (!isArray(get(record, dataIndex))) {
        return (<span class={moneyClass}>--</span>)
      } else {
        let salary_type = record.salary_type
        return (<div class={moneyClass}>
          {salary_type === 0 ? getReferenceConstantMap('salaryTypeEnum').DISCUSS_PERSONALLY.label : `${record.salary_section?.join('-')}K (${getReferenceItemsValueMap('salaryTypeEnum')[salary_type].label})`}
        </div>)
      }
    }
  }
)