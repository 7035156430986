<template>
  <custom-api-select
    v-bind="props"
    v-model:api-params="apiParams"
    :api="systemApi.userList"
    :immediate="true"
    value-field="id"
    label-field="username"
    :allow-clear="true"
  />
</template>
<script lang="ts" setup>
import { usersSelectProps } from './props'
import { systemApi } from '@/api/system'
const props = defineProps(usersSelectProps)
const { apiParams } = props
</script>
