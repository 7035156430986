import { ExtractPropTypes, PropType } from 'vue'
import type { CascaderProps } from 'ant-design-vue/es/cascader'

export const apiCascaderProps = {
  api: {
    type: Function
  },
  referenceKey: {
    type: String as PropType<'departmentTree'>
  }
}

export type ApiCascaderProps = Partial<ExtractPropTypes<typeof apiCascaderProps>> & CascaderProps