//本地枚举请到reference-creator.js中添加
export const localReference = {
    recommendHistoryLocalEnum: [{"label":"不限","value":"no_limit","key":"NO_LIMIT"},{"label":"推荐过","value":"recommended","key":"RECOMMENDED"},{"label":"禁止推荐","value":"no_recommended","key":"NO_RECOMMENDED"}],
    recommendStatusLocalEnum: [{"label":"不限","value":"no_limit","key":"NO_LIMIT"},{"label":"可推荐","value":"recommend","key":"RECOMMEND"},{"label":"禁止推荐","value":"un_recommend","key":"UN_RECOMMEND"}],
    dateRangeLocalEnum: [{"label":"当月","value":"currentMonth","key":"CURRENT_MONTH"},{"label":"三个月","value":"threeMonth","key":"THREE_MONTH"},{"label":"半年","value":"halfYear","key":"HALF_YEAR"},{"label":"一年","value":"oneYear","key":"ONE_YEAR"},{"label":"三年","value":"threeYear","key":"THREE_YEAR"},{"label":"自定义","value":"custom","key":"CUSTOM"}],
    frontendAccountStatusLocalEnum: [{"value":0,"label":"冻结","color":"red","key":"FREEZE"},{"value":1,"label":"激活","color":"green","key":"ACTIVE"}],
    showOrHidden: [{"value":0,"label":"显示","key":"SHOW"},{"value":1,"label":"隐藏","key":"HIDEEN"}],
    frontendAccountEmailTypeLocalEnum: [{"value":"163","label":"163","key":"163"},{"value":"126","label":"126","key":"126"},{"value":"tencent","label":"tencent","key":"TENCENT"},{"value":"qq","label":"qq","key":"QQ"}],
    moneyLocalEnum: [{"value":0,"label":"5W","key":"WU_WAN"},{"value":1,"label":"6W","key":"LIU_WAN"},{"value":2,"label":"7W","key":"QI_WAN"},{"value":3,"label":"8W","key":"BA_WAN"},{"value":4,"label":"9W","key":"JIU_WAN"},{"value":5,"label":"10W","key":"SHI_WAN"},{"value":6,"label":"11W","key":"SHIYI_WAN"},{"value":7,"label":"12W","key":"SHIER_WAN"},{"value":8,"label":"13W","key":"SHISAN_WAN"},{"value":9,"label":"14W","key":"SHISI_WAN"},{"value":10,"label":"15W","key":"SHIWU_WAN"},{"value":11,"label":"16W","key":"SHILIU_WAN"}],
    salaryTypeLocalEnum: [{"value":1,"label":"面议","key":"FACE_TYPE"},{"value":2,"label":"月薪","key":"MONTH_SALARY_TYPE"},{"value":3,"label":"年薪","key":"YEAR_SALARY_TYPE"}],
    sexLocalEnum: [{"value":0,"label":"未知","key":"UNKNOWN","color":"#A8A8A8","bgColor":"#ededed"},{"value":1,"label":"男","key":"MAN","color":"#2a9df3","bgColor":"#e5f3fe"},{"value":2,"label":"女","key":"WOMAN","color":"#f2503c","bgColor":"#ffece7"}],
    marriageLocalEnum: [{"value":0,"label":"未知","key":"UNKNOWN"},{"value":1,"label":"未婚","key":"NO_MARRIAGE"},{"value":2,"label":"已婚","key":"MARRIAGED"},{"value":3,"label":"离异","key":"LEAVE_MARRIAGED"},{"value":4,"label":"丧偶","key":"LOSE"}],
    statusLocalEnum: [{"value":0,"label":"在职/无求职打算","key":"STAY_NO_INTEND"},{"value":1,"label":"在职/看看新机会","key":"STAY_LOOK_INTEND"},{"value":2,"label":"离职/正在找工作","key":"LEAVE_FINDING"}],
    levelLocalEnum: [{"value":0,"label":"一般","key":"YI_BAN"},{"value":1,"label":"良好","key":"LIANG_HAO"},{"value":2,"label":"熟练","key":"SHU_LIAN"},{"value":3,"label":"精通","key":"JING_TONG"}],
    positionSalaryTypeLocalEnum: [{"value":1,"label":"长白班","key":"LONG"},{"value":2,"label":"两班倒","key":"TWO"},{"value":3,"label":"正式工","key":"NORMAL"},{"value":4,"label":"小时工","key":"HOUR"}],
    yesOrNoEnum: [{"value":1,"label":"是","key":"YES"},{"value":0,"label":"否","key":"NO"}],
    reportDataType: [{"value":"my","label":"仅本人","key":"my"},{"value":"dep","label":"部门及下属","key":"dep"},{"value":"all","label":"全部数据","key":"all"},{"value":"diy","label":"自定义","key":"diy"}],
    hightlightLocalEnum: [{"key":"ignore","label":"忽略","color":"eee","sort":1,"value":1},{"key":"be-pending","label":"待定","color":"c0c0c0","sort":2,"value":2},{"key":"key-follow","label":"重点跟进","color":"ffb1d3","sort":3,"value":3},{"key":"highlight","label":"高亮","color":"fdfeae","sort":4,"value":4},{"key":"keynote","label":"重点","color":"a6f2fd","sort":5,"value":5},{"key":"clear","label":"清除","color":"","value":99}],
  }