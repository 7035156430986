<template>
  <div class="flex items-center">
    <a-select
      v-bind="bindSelectAttr"
      v-model:value="type"
      :class="selectClass"
      @change="selectChange"
    />
    <a-range-picker
      v-if="type ===props.customValue"
      v-bind="bindDateRangeAttr"
      v-model:value="range"
      class="ml-4"
      :class="dateRangeClass"
      @change="rangeDateChange"
    />
  </div>
</template>
<script lang="ts" setup>
import { dateRangeSelectProps } from './props'
import { get } from 'lodash-es'
import { onMounted } from 'vue'
import { useAppStore } from '@/store'

const props = defineProps(dateRangeSelectProps)
const { reference } = useAppStore()
type EmitEvents = {
  (e:'change', ...value):void
  (e:'update:value', ...value):void
}
const emits = defineEmits<EmitEvents>()
const type = ref()
const range = ref()
const selectChange = v => {
  type.value = v
  let tmp = {
    [props.valueField]: (v as any) ?? '',
    [props.rangeValueField]: (v as any) ?? range.value
  }
  if (tmp?.[props.valueField] !== props.customValue) {
    tmp = {
      [props.valueField]: (v as any) ?? '',
      [props.rangeValueField]: undefined
    }
    emits('change', tmp)
    emits('update:value', tmp)
  }
}
const rangeDateChange = v => {
  range.value = v
  let tmp = {
    [props.valueField]: type.value,
    [props.rangeValueField]: v as any
  }
  emits('change', tmp)
  emits('update:value', tmp)
}
const init = (date?:string, range?:any) => {
  let tmp = {
    [props.valueField]: date ?? props.defaultType,
    [props.rangeValueField]: range ?? undefined
  }
  emits('change', tmp)
  emits('update:value', tmp)
  nextTick(() => {
    type.value = date ?? props.defaultType
  })
}
onMounted(() => {
  if (props.defaultType) {
    init()
  }
  if (props.value) {
    init(props.value?.[props.valueField], props.value?.[props.rangeValueField])
  }
})
watch(() => props.value, newVal => {
  if (!newVal) {
    nextTick(() => {
      type.value = undefined
      range.value = undefined
    })
  } else {
    type.value = newVal?.[props.valueField]
    range.value = newVal?.[props.rangeValueField]
  }
}, {
  immediate: true
})
const getOptions = () => {
  let result:any = []
  if (props.selectAttr?.options) {
    result = props.selectAttr?.options
  } else if (props.referenceKey) {
    result = (get(reference, props.referenceKey) ?? [])
  }
  return result
}
const bindSelectAttr = computed(() => ({
  options: getOptions(),
  labelField: 'label',
  valueField: 'value',
  placeholder: '请选择',
  ...props.selectAttr,
}))
const bindDateRangeAttr = computed(() => ({
  placeholder: ['自定义开始日期', '自定义结束日期'],
  valueFormat: 'YYYY-MM-DD',
  ...props.dateRangePickerAttr,
}))
</script>
