import { PropType, ExtractPropTypes } from 'vue'
export const referenceTagProps = {
  referenceKey: {
    type: String as PropType<Reference.ReferenceKey>,
    default: ''
  },
  model: {
    type: Object,
    default: () => ({})
  },
  dataIndex: {
    type: String,
    default: ''
  },
  value: {
    type: [String, Number],
    default: undefined
  }
}

export type ReferenceTagProps = Partial<ExtractPropTypes<typeof referenceTagProps>>