import { request } from '@/utils/request'

/** 系统模块接口 */
const systemApi = {
  addUser: params => request.post('supplierUsers/store', params),
  updateUser: params => request.post('supplierUsers/update', params),
  delUser: id => request.post(`supplierUsers/${id}/del`),
  userList: params => request.get('supplierUsers/index', params),
  resetPassword: params => request.post(`supplierUsers/${params.id}/editPassword`, params),
  bindEmail: params => request.post('user/store-email', params),
  delEmail: id => request.post('user/destroy-email', { id })
}

export { systemApi }