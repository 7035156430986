import { useSiteConfig } from '@/hooks/site-config'
import { computed, unref, ref, Ref, ComputedRef, h } from 'vue'
import { cloneDeep, isFunction, isBoolean, isString, omit, get, isArray, forEach } from 'lodash-es'
import RowAction from '../components/row-action.vue'
import { Tag, Image, Badge, Tooltip, Table } from 'ant-design-vue'
import { PaginationProps } from 'ant-design-vue/es'
import { useAppStore, useSystemStore } from '@/store'
import { DragOutlined, InfoCircleOutlined } from '@ant-design/icons-vue'
import UserInfoModal from '@/components/UserInfoModal.vue'
import { useSortable } from '@/hooks/sortable'
import { transformObjToDotStrObj } from '@/utils/object'
import { router } from '@/router'
import dayjs from 'dayjs'
import { handleMoney } from '@/views/workbench/common/common'
const { table } = useSiteConfig

export declare type GetColumnsParm = { ignoreIndex?: boolean; ignoreAction?: boolean; ignoreHidden?: boolean }

export function useColumn(
  tableRef,
  props: ComputedRef<IGridTable.Props>,
  paginationRef: ComputedRef<PaginationProps | boolean>,
  rawDataSourceRef: Ref<any[]>,
  actions: { handleEdit: Fn; handleDel: Fn },
) {
  const systemStore = useSystemStore()
  const scrollConfig = unref(props).scroll as { x?: string | number | true, y?: string | number }
  const scrollRef = ref({
    x: scrollConfig?.x || 'max-content',
    y: scrollConfig?.y
  } as { x?: string | number | true, y?: string | number })
  // 操作列宽度
  const actionWidth = ref(80)
  const expandWidth = ref(0)
  const rawColumnsRef = ref(unref(props).columnSchemas)
  const columnsRef = computed(() => unref(allColumnsRef!)?.filter(column => !column.defaultHidden))
  const allColumnsRef = computed<IGridTable.ColumnProps[]>(() => {
    const {
      draggable,
      indexable,
      editable,
      actionable,
      deleteable,
      isFormTable,
      rowActionsWidth,
      rowActions: rawRowActions = [],
    } = unref(props)

    let columns = (cloneDeep(unref(rawColumnsRef)) as IGridTable.ColumnProps[]).map((column, index) => {
      // if (column === undefined) {
      //   console.log(unref(props).columnSchemas, 'sddd')

      //   debugger
      // }
      if (column) {
        column.customHeaderCell = (c: ColumnType) => ({
          class: `grid_table_th_${index} font-bold`
        })
        column!.customCell = (record, rowIndex, c: ColumnType) => ({
          class: `grid_table_td_${index}`,
        })
      }


      if (column.dataIndex && isString(column.dataIndex) && column.dataIndex.indexOf('.') > 0) {
        column.dataIndex = column.dataIndex.split('.')
      }
      if (column.enumSchemas) {
        column.customRender = function({ text, record }) {
          const enumItem = column.enumSchemas?.find(e => e.value?.toString() === text?.toString())
          let domEl: any
          if (enumItem?.color && enumItem?.bgColor) {
            domEl = h(Tag, { color: enumItem.bgColor }, () => [h('span', { style: { color: enumItem.color } }, enumItem.label)])
          } else if (enumItem?.color) {
            domEl = h(Badge, {
              color: enumItem?.color,
              text: enumItem?.label
            })
          } else {
            domEl = enumItem?.label
          }
          if (column.failStatus && column.failStatus.statusList.findIndex(e => e.toString() === text?.toString()) !== -1) {
            return h(Tooltip, {
              ...omit(column.failStatus, ['statusList', 'failMsg']),
              title: isFunction(column.failStatus.failMsg) ? column.failStatus.failMsg(record) : column.failStatus.failMsg,
              color: column.failStatus?.color ?? '#F53F3F'
            }, () => [
              domEl,
              h(InfoCircleOutlined, {
                class: 'ml-2 text-red-600',
              })
            ])
          } else {
            return domEl
          }
        }
      }

      if (column.referenceKey) {
        const { reference } = useAppStore()
        column.customRender = function({ text }) {
          let enumItem = reference?.[column.referenceKey as string]?.find(e => e.value === text)
          let domEl: any
          if (enumItem?.color && enumItem?.bgColor) {
            domEl = h(Tag, { color: enumItem.bgColor }, () => [h('span', { style: { color: enumItem.color } }, enumItem.label)])
          } else if (enumItem?.color) {
            domEl = h(Badge, {
              color: enumItem?.color,
              text: enumItem?.label
            })
          } else {
            domEl = enumItem?.label
          }
          return domEl
        }
      }

      if (column.isUser) {
        column.customRender = function({ text, record }) {
          return text ? h(UserInfoModal, {
            userId: get(record, column.userField ?? 'id'),
            userName: text
          }) : '--'
        }
      }

      if (column.thumbable) {
        column.customRender = function({ text }) {
          return h(Image, {
            src: text,
            width: 120
          })
        }
      }


      if (column.isMoney) {
        column.customRender = function({ text }) {
          let moneyText = handleMoney(text)
          return h('span', {
            title: moneyText,
            class: 'text-[#e57a7a] font-bold'
          }, moneyText)
          // return h('span', {
          //   title: `￥${text}`,
          // }, text)
        }
      }
      if (column.isHandleLine) {
        column.customRender = function({ text }) {
          let textArr: string[] = text.split('\n')
          return (<div style={{ width: column.width }} class={column.className}>
            {textArr ? textArr.map(item => (<div>
              {item}
            </div>)) : (<span>{text}</span>)}
          </div>)
        }
      }
      if (column.addonAfter) {
        column.customRender = function({ text }) {
          return h('span', {
            title: text + column.addonAfter,
            class: ''
          }, text + column.addonAfter)
        }
      }

      // 表格时间YYYY-MM-DD
      if ((!column.showOriginDate
        && (
          isString(column.dataIndex) && column.dataIndex?.includes('_at')
          || isArray(column.dataIndex) && column.dataIndex.join('.')?.includes('_at')
        )) || column.showFormatDate) {
        column.customRender = function({ text }) {
          return text && dayjs(text).isValid() ? dayjs(text).format('YYYY-MM-DD') : text
        }
      }
      // 为了兼容之前的，当等于undefined时，
      if (!column.zeroToLine && column.zeroToLine !== undefined && !column.customRender) {
        column.customRender = function({ text }) {
          return `${text}`
        }
      }

      if (column.needSort && (!column.sorter) && isString(column.dataIndex)) {
        column.sorter = column.needSort
      }

      if (column.canJumpToDetail) {
        const getPushParam = (toDetail: IGridTable.ColumnProps['toDetail'], id) => {
          if (toDetail!.paramType === 'params') {
            return {
              name: toDetail!.routeName,
              params: { id }
            }
          }
          return toDetail!.routeName ? {
            name: toDetail!.routeName,
            query: { id }
          } : {
            path: toDetail!.routePath,
            query: { id }
          }
        }

        column.customRender = function({ text, record }) {
          if (!text) {

            /* 兼容table空字段显示 ‘--’ 显示为空时直接返回空 */
            return ''
          }
          const id = column.toDetail!.idKey ? transformObjToDotStrObj(record)[column.toDetail!.idKey] : record.id
          const showText = computed(() => (isFunction(column.toDetail?.customRenderTextFn) ? column.toDetail?.customRenderTextFn(record) : text))
          const textVnode = h('span', {
            class: 'cursor-pointer text-primaryColor-default ...',
            title: `去${text}详情`,
            onClick: () => {
              router.push(getPushParam(column.toDetail, id))
              if (isFunction(column?.toDetail?.afterCallback)) {
                column?.toDetail?.afterCallback(record)
              }
            }
          }, showText.value)
          return textVnode
        }
      }

      if (column.isEllipsisToTooltip) {
        column.customRender = function({ text, record }) {
          text = isFunction(column.isEllipsisToTooltip) ? column.isEllipsisToTooltip(record) : text
          let element
          let t = ''
          if (isArray(text) && text.length > 1) {
            element = (<div>{text.map((item, index) =>
              <div
                class="py-1"
                style={index === 0 ? {} : { borderTop: '1px solid rgb(239, 239, 239)' }}
              >
                {item}
              </div>)}
            </div>)
            t = text[0]
            t += '...'
          } else if (isArray(text) && text.length === 1) {
            t = text[0]
          } else if (isString(text) && text.length > 7) {
            element = text
            t = `${text.substring(0, 7)}...`
          } else {
            t = text
          }

          return element
            ? h(Tooltip, { title: element }, () => [t])
            : t
        }
      }

      return column
    })
    if (!columns) {
      return []
    }


    // 拖拽列
    if (draggable) {
      columns.unshift({
        width: 60,
        title: '拖拽',
        align: 'center',
        flag: 'drag',
        customRender: () => h(DragOutlined, {
          class: 'mr-2 cursor-move table-coulmn-drag-icon',
        }),
      })
      nextTick(() => {
        // Drag and drop sort
        const el = tableRef.value.$el.querySelector('.ant-table-tbody') as HTMLDivElement
        const { initSortable } = useSortable(el, {
          handle: '.table-coulmn-drag-icon',
          onEnd: evt => {
            const { oldIndex, newIndex } = evt
            nextTick(() => {
              const rawDataSource = unref(rawDataSourceRef)
              if (oldIndex > newIndex) {
                rawDataSource.splice(newIndex - 1, 0, rawDataSource[oldIndex - 1])
                rawDataSource.splice(oldIndex, 1)
              } else {
                rawDataSource.splice(newIndex, 0, rawDataSource[oldIndex - 1])
                rawDataSource.splice(oldIndex - 1, 1)
              }
            })
          },
        })
        initSortable()
      })
    }
    // 索引列
    if (indexable) {
      columns.unshift({
        width: 70,
        title: '序号',
        align: 'center',
        flag: 'index',
        customRender: ({ index }) => {
          const pagination = unref(paginationRef)
          if (isBoolean(pagination) && !pagination) {
            return index + 1
          }
          const { current = 1, pageSize = table.defaultPageSize } = pagination as PaginationProps
          return ((current < 1 ? 1 : current) - 1) * pageSize + index + 1
        },
      })
    }

    const rowActions = cloneDeep(rawRowActions)
    if ((isFunction(deleteable) && deleteable()) || deleteable) {
      rowActions.push({
        label: '删除',
        confirm: '确定删除吗？',
        click: actions.handleDel,
      })
    }
    if (((isFunction(editable) && editable()) || editable)) {
      rowActions.unshift({
        label: '编辑',
        click: actions.handleEdit,
      })
    }

    // 操作列
    if (actionable && (rowActions.length > 0 || isFormTable)) {
      columns.push({
        width: rowActionsWidth ?? unref(actionWidth),
        title: '操作',
        align: 'center',
        fixed: 'right',
        dataIndex: 'action',
        flag: 'action',
        class: 'grid_table_th_action z-10',
        customRender: ({ index }) => {
          const rawRecord = rawDataSourceRef.value[index]
          return h(RowAction, {
            actions: rowActions,
            record: rawRecord ?? {},
            rowActionsShowIcon: unref(props).rowActionsShowIcon
          })
        },
      })
    }

    return columns
  })

  // 自动宽度
  function autoColumnWidth() {
    return new Promise(async resolve => {
      actionWidth.value = 0
      await nextTick()
      const el = tableRef.value.$el as HTMLElement
      actionWidth.value = getWidth(el, actionWidth.value, 'grid-table-actions') + 20
      expandWidth.value = getWidth(el, expandWidth.value, 'ant-table-row-expand-icon-cell')
      if (systemStore.isPc && (unref(props).autoWidth || unref(props).autoHeight || scrollConfig?.y)) {
        scrollRef.value.y = undefined
        unref(props).columnSchemas?.forEach((item: IGridTable.ColumnProps) => {
          if (!item.width) {
            let column = arrFind(rawColumnsRef.value as Array<IGridTable.ColumnProps>, 'dataIndex', item.dataIndex)
            if (column?.width) {
              delete column.width
            }
          }
        })
        h
        await nextTick()
        setTimeout(async () => {
          const tableElement = el.querySelector('.ant-table-content') as HTMLElement
          const columnLength = rawColumnsRef.value!.length - (tableElement?.scrollWidth > tableElement?.clientWidth ? 0 : 1)
          rawColumnsRef.value!.forEach((column: IGridTable.ColumnProps, index) => {
            let width = 0
            if (!column.width && index < columnLength) {
              width = getWidth(el, width, `grid_table_th_${index}`)
              width = getWidth(el, width, `grid_table_td_${index}`)
              if (width) {
                column.width = width + 1

              }
            }
          })
          console.log(rawColumnsRef.value, 'rawColumnsRef.value')

          scrollRef.value.y = scrollConfig?.y
          autoHeight()
          await nextTick()
          resolve(null)
        })
      } else {
        scrollRef.value.x = '100%'
      }
    })
  }
  function autoHeight() {
    if (systemStore.miniScreen) {
      scrollRef.value.y = undefined
      return
    }
    const el = tableRef.value.$el.querySelector('tbody')
    let height = window.innerHeight - offsetTop(el) - (80)
    if (height > 200) {
      scrollRef.value.y = height
    }
  }

  function offsetTop(el: HTMLElement, filterClass: Array<string> = []): any {
    for (let key in filterClass) {
      if (el.className.indexOf(filterClass[key]) > -1) {
        return 0
      }
    }
    if (el.offsetParent) {
      return offsetTop(el.offsetParent as HTMLElement, filterClass) + el.offsetTop
    }

    return el.offsetTop
  }


  function getWidth(el: HTMLElement, width: number, dataIndex: string) {
    forEach(el.getElementsByClassName(dataIndex), (item: Element) => {
      let offsetWidth = (item as HTMLElement).offsetWidth
      if (width < offsetWidth) {
        width = offsetWidth
      }
    })
    return width
  }

  // 递归查找数组
  function arrFind(arr: any, field: string, value: any, children = 'children'): any {
    for (let key in arr) {
      if (Object(arr[key])[field] == value) {
        return arr[key]
      }
      if (Object(arr[key])[children]) {
        let find = arrFind(Object(arr[key])[children], field, value, children)
        if (find) {
          return find
        }
      }
    }
    return null
  }

  function setColumns(columns: IGridTable.ColumnProps[]) {
    const newColumns = cloneDeep(columns)
    rawColumnsRef.value = newColumns
  }

  function getColumns(opt: IGridTable.GetColumnParam) {
    let columns = unref(allColumnsRef)
    if (opt?.ignoreHidden) {
      columns = columns.filter(column => !column.defaultHidden)
    }
    if (opt?.ignoreDrag) {
      columns = columns.filter(column => column.flag !== 'drag')
    }
    if (opt?.ignoreIndex) {
      columns = columns.filter(column => column.flag !== 'index')
    }
    if (opt?.ignoreAction) {
      columns = columns.filter(column => column.flag !== 'action')
    }
    return columns
  }
  function getIsSummary(column) {
    let summary = false
    if (column.children) {
      column.children.forEach(item => {
        if (!summary) {
          summary = getIsSummary(item)
        }
      })
      return summary
    } else {
      if (column.summary) {
        summary = true
      }
      return summary
    }
  }
  const isSummary = computed(() => {
    let summary = false
    unref(allColumnsRef).map((column, index) => {
      if (!summary) {
        summary = getIsSummary(column)
      }
    })
    return summary
  })
  function getSummaryCell(data, column, columns) {
    if (column.children) {
      column.children.forEach(item => {

        getSummaryCell(data, item, columns)
      })
    } else {
      let content: any = null
      const record = {}
      if (column.summary) {
        content = 0
        unref(data).forEach(item => {
          for (const key in item) {
            if (!record[key]) {
              record[key] = 0
            }
            if (!isNaN(parseFloat(item[key]))) {
              record[key] += parseFloat(item[key])
            }
          }
          if (isArray(column.dataIndex)) {
            if (!isNaN(parseFloat(item[column.dataIndex[0]][column.dataIndex[1]]))) {
              content += parseFloat(item[column.dataIndex[0]][column.dataIndex[1]])
            }
          } else if (!isNaN(parseFloat(item[column.dataIndex]))) {
            content += parseFloat(item[column.dataIndex])
          }
        })
        content = content.toFixed(2)
        if (typeof column.summary == 'function') {
          content = column.summary({
            text: content,
            record
          })
        }
      }
      // todo，通过default已减少警告，但还有少部分警告
      if (!column.defaultHidden) {
        columns.push(h(Table.Summary.Cell, {
          class: 'bg-[#fafafa]',
          index: columns.length,
          align: column.align || 'left'
        }, { default: () => content }))
      }
    }
  }
  function getSummary(data) {
    const columns = props.value.selectable === false ? [] : [h(Table.Summary.Cell, { index: 0 })]
    unref(allColumnsRef).map((column, index) => {
      getSummaryCell(data, column, columns)
    })
    return h(Table.Summary, { fixed: true }, { default: () => h(Table.Summary.Row, columns) })
  }
  return {
    columnsRef,
    allColumnsRef,
    scrollRef,
    setColumns,
    getColumns,
    isSummary,
    getSummary,
    autoColumnWidth,
    autoHeight
  }
}
