<style lang="less" scoped>
@nav-size-height: 60px;
@layout-max-width: 1100px;

.layout {
  height: 100vh;
  width: 100%;
}

.layout-navbar {
  padding: 0;
  background-color: #FFFFFF;
  // background-color: #1373c5;
}

.layout-sider {
  position: "fixed";
  top: 0;
  bottom: 0;
  left: 0;
  overflow: "auto";
  height: "100vh";

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    // border-bottom: 0.5px solid #58636B;
    box-sizing: border-box;
    height: 52px;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    color: #1373C5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #FFFFFF;
    cursor: pointer;

    &.collapsed {
      background-image: url("@images/logo-color.jpg");
      background-size: 86%;
    }
  }
}
</style>

<template>
  <a-layout
    class="layout"
    style="height: 100vh;"
    theme="light"
  >
    <a-layout-header class="z-20 shadow layout-navbar">
      <Header />
    </a-layout-header>
    <a-layout>
      <a-layout-sider
        v-model:collapsed="systemStore.menuCollapsed"
        class="layout-sider"
        collapsible
        :width="214"
        :collapsed-width="50"
      >
        <Menu />
      </a-layout-sider>
      <a-layout-content class="z-10 overflow-auto">
        <Content />
      </a-layout-content>
      <!-- <a-layout-footer>
        <Footer />
      </a-layout-footer> -->
    </a-layout>
  </a-layout>
</template>

<script lang="ts" setup>
import Menu from './components-side/menu.vue'
import Content from './components-side/content.vue'
import Header from './components-side/header.vue'
import { useSystemStore } from '../../store/modules/system'
const systemStore = useSystemStore()
// const collapsed = ref(false)
</script>
