<template>
  <Upload
    v-bind="bindAttrs"
  />
</template>
<script lang="ts" setup>
import { erpUploadProps } from './props'
const props = defineProps(erpUploadProps)
const attrs = useAttrs()
const bindAttrs = computed(() => ({
  uploadUrl: '/file/erp',
  valueIsId: true,
  idField: 'out_id',
  ...attrs,
  ...props
}))
</script>
