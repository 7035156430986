import { ExtractPropTypes } from 'vue'
import type { SelectProps } from 'ant-design-vue/es/select'
// type SelectorTypes = keyof Reference.SelectorTypes

export const usersSelectProps = {
  apiParams: {
    type: Object,
    default: () => ({}),
  },
  placeholder: {
    type: String,
    default: '请选择用户'
  }
}

export type UsersSelectProps = Partial<ExtractPropTypes<typeof usersSelectProps>> & SelectProps