<template>
  <a-cascader v-bind="bindAttrs" />
</template>
<script lang="ts" setup>
import { globalApi } from '@/api/global'
import { getCacheApi } from '../../common-components/custom-api-select/api-cache'

type EmitEvents = {
  (e: 'change', ...value): void,
  (e: 'update:value', ...value): void,
  (e: 'options-change', options:Recordable[]):void
}
const emits = defineEmits<EmitEvents>()
const attrs = useAttrs()
const options = ref<Recordable[]>([])

onMounted(() => {
  fetch()
})

async function fetch() {

  // let res = await globalApi.getIndustry()
  let res = await getCacheApi(globalApi.getIndustry, {})
  options.value = res
  emits('options-change', options.value)
}
const bindAttrs = computed(() => ({
  options: unref(options),
  onChange: (...newValue) => emits('change', ...newValue),
  'onUpdate:value': (...newValue) => emits('update:value', ...newValue),
  ...attrs
}))

</script>