
import { userApi } from '@/api/user'
import { useMessage } from '@/hooks/message'
import { pick } from 'lodash-es'
import globalModelForm from '@/hooks/global-model-form'
import { authApi } from '@/api/auth'
import { useUserWithoutStore } from '@/store'
interface IProps{
  basicFormEl:Ref<IForm.Expose | undefined>,
  updatePwdFormEl: Ref<IForm.Expose | undefined>,
  emailGridTableEl:Ref<IGridTable.Expose | undefined>,
}
const userStoreRef = computed(() => useUserWithoutStore())
const updatePwdFormSchemas: IForm.Schema[] = [
  // {
  //   label: '原密码',
  //   field: 'old_password',
  //   required: true,
  //   component: 'InputPassword',
  // } as IForm.Schema<'InputPassword'>,
     {
       label: '新密码',
       field: 'password',
       required: true,
       component: 'InputPassword',
     } as IForm.Schema<'InputPassword'>,
  // {
  //   label: '确认密码',
  //   field: 'password_confirmation',
  //   required: true,
  //   component: 'InputPassword',
  // } as IForm.Schema<'InputPassword'>,
]
const usePersonal = ({ basicFormEl, updatePwdFormEl, emailGridTableEl }:IProps) => {
  const basicFormSchemas: IForm.Schema[] = [
    {
      label: '角色',
      field: 'role.name',
      required: true,
      component: 'Input',
      componentProps: {
        disabled: true
      }
    } as IForm.Schema<'Input'>,
    {
      label: '头像',
      field: 'avatar',
      component: 'Upload',
      componentProps: {
        acceptType: 'img',
        maxNum: 1,
        maxSize: 2,
        uploadUrl: '/user/update-avatar'
      },
    } as IForm.Schema<'Upload'>,
    {
      label: '名称',
      field: 'name',
      required: true,
      component: 'Input',
      componentProps: {
        disabled: true
      }
    } as IForm.Schema<'Input'>,
    {
      label: '手机',
      field: 'mobile',
      required: true,
      component: 'Input',
      componentProps: {
        disabled: true
      }
    } as IForm.Schema<'Input'>,
      {
        label: '描述',
        field: 'slug',
        component: 'InputTextArea',
      } as IForm.Schema<'InputTextArea'>,
  ]

  const basicFormAttr = ref<IForm.Props>({
    baseItemCol: {
      span: 12,
    },
    layout: 'vertical',
    schemas: basicFormSchemas,
    onSubmit: async v => {
      await userApi.updateUserInfo(pick(v, ['slug']))
      await authApi.getUserInfo()
      useMessage.success('操作成功')
    }
  })
  const updatePwdFormAttr = ref<IForm.Props>({
    baseItemCol: {
      span: 24,
    },
    layout: 'vertical',
    schemas: updatePwdFormSchemas,
    onSubmit: async v => {
      await userApi.resetPassword(Object.assign(v, { id: userStoreRef.value?.user?.id }))
    }
  })

  const emailGridTableProps = ref<IGridTable.Props>({
    title: '邮箱信息',
    deleteable: true,
    api: {
      getList: async params => {
        const res = await userApi.emails(params)
        return res
      },
      del: userApi.delEmail
    },
    columnSchemas: [
      {
        title: '邮箱',
        dataIndex: 'email',
      },
    ],
    tableActions: [
      {
        label: '新增邮箱',
        type: 'primary',
        click: () => {
          globalModelForm.init({
            title: '新增邮箱',
            destroyOnClose: true,
            schemas: [
              {
                label: '邮箱',
                field: 'email',
                required: true,
                component: 'Input',
              } as IForm.Schema<'Input'>,
            ],
            api: async values => {
              await userApi.storeEmail(values)
              emailGridTableEl.value?.reload()
            }
          })
        }
      }
    ]
  })

  return {
    basicFormAttr,
    updatePwdFormAttr,
    emailGridTableProps,
  }
}
export {
  usePersonal,
  updatePwdFormSchemas
}