<template>
  <a-cascader v-bind="bindAttrs" />
</template>
<script lang="ts" setup>
import { getCacheApi } from '../../common-components/custom-api-select/api-cache'
import { apiCascaderProps } from './props'
import { selectorApi } from '@/api/selector'

type EmitEvents = {
  (e: 'change', ...value): void,
  (e: 'update:value', ...value): void,
  (e: 'options-change', options:Recordable[]):void
}
const emits = defineEmits<EmitEvents>()
const attrs = useAttrs()
const options = ref<Recordable[]>([])
const props = defineProps(apiCascaderProps)
const apiMap = {
  departmentTree: selectorApi.departmentTree
}

onMounted(() => {
  fetch()
})

async function fetch() {
  let fn = props.referenceKey ? apiMap[props.referenceKey] : props.api
  if (fn) {
    let res = await getCacheApi(fn, {})
    options.value = res
    emits('options-change', options.value)
  }
}
const bindAttrs = computed(() => ({
  options: unref(options),
  onChange: (...newValue) => emits('change', ...newValue),
  'onUpdate:value': (...newValue) => emits('update:value', ...newValue),
  ...attrs
}))

</script>