<template>
  <div class="flex bg-primaryColor-default">
    <div
      class="my-[6px] ml-[10px] w-full flex-1 "
      style="position: relative;z-index: 999;box-sizing: border-box;"
    >
      <historyPage
        ref="historyPageRef"
        @after-close-all="closePageAll"
        @after-close="closePage"
        @page-change="pageChange"
      >
        <a-breadcrumb
          v-if="parentPath.length == 1"
          class="mb-1"
        >
          <a-breadcrumb-item
            v-for="routeConfig in parentPath"
            :key="routeConfig?.name"
          >
            <router-link :to="{ name: routeConfig?.name }">
              {{ routeConfig?.meta.title }}
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>{{ currentRouteTitle }}</a-breadcrumb-item>
        </a-breadcrumb>
      </historyPage>
    </div>

    <!-- <nav-bar style="flex: 0 0 200px;" /> -->
  </div>
</template>
<script lang="ts" setup>
import { keepAliveStore } from '@/store/modules/keep-alive'
import { useRouter, onBeforeRouteUpdate, RouteLocationNormalized } from 'vue-router'
import historyPage from './navbar/history-page.vue'
import { nameToRouterConfigMap } from '@/router'
import { isEmpty } from 'lodash-es'
const historyPageRef = ref<InstanceType<typeof historyPage>>()
const closePage = (routeName: string | string[]) => {
  keepAliveStore().removeCache(routeName)
}
const closePageAll = () => {
  keepAliveStore().clearCache()
}
const pageChange = (activePage: any, route:RouteLocationNormalized) => {
  const { caches, removeCache } = keepAliveStore()
  if (route?.meta.keepAlive && caches.includes(route?.name as string) && JSON.stringify(activePage.urlParams) !== (JSON.stringify(isEmpty(route.query) ? route.params : route.query))) {
    removeCache(activePage?.name)
  }
}

type EmitEvents = {
  (e: 'handleSideVisible', v: boolean): void
}
const emits = defineEmits<EmitEvents>()

// 因为 vue-router 内部使用 Provide 将 router 对象注入到 vue 实例中，useRouter 需要使用 inject 获取 router 实例
// 而 inject 需要用在 setup 顶层，
const router = useRouter()
const parentPath = ref<Recordable[]>([])
const currentRouteTitle = ref()

function updateBreadcrumb(to) {
  parentPath.value = []
  const currentRoute = unref(to || router?.currentRoute)
  const currentRouteName = currentRoute?.name as string
  currentRouteTitle.value = currentRoute?.meta.title
  let currentRouteConfig = nameToRouterConfigMap[currentRouteName]
  // emits('handleSideVisible', currentRouteName !== 'workbenchIndex')
  while (currentRouteConfig?.meta.parentNameForBreadcrumb) {
    currentRouteConfig
      = nameToRouterConfigMap[currentRouteConfig?.meta.parentNameForBreadcrumb]
    parentPath.value.unshift(currentRouteConfig)
  }
}

onBeforeRouteUpdate(to => {
  updateBreadcrumb(to)
})
updateBreadcrumb(null)
defineExpose({
  resetHistoryPage: () => {
    historyPageRef.value?.reset()
  }
})
</script>