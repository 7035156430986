import { ExtractPropTypes } from 'vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import { Tag, Modal, FormItemRest } from 'ant-design-vue/es'
import { useAppStore } from '@/store'
import { cloneDeep, isArray } from 'lodash-es'
import GridTable from '@/components/table/grid-table.vue'
import { systemApi } from '@/api/system'
const userProps = {
  value: {
    type: Array
  },
  title: {
    type: String,
    default: '参与顾问'
  },
  hideTag: {
    type: Boolean,
    default: false
  },
  disableUser: {
    type: Array,
    default: []
  }
}
export type JobManagerSelectProps = Partial<ExtractPropTypes<typeof userProps>>

export default defineComponent({
  name: 'UserModalSelect',
  inheritAttrs: false,
  props: userProps,
  emits: ['update:value'],
  setup(props, ctx) {
    const { emit, expose } = ctx
    const { getReferenceItemsValueMap } = useAppStore()
    const users = ref<any>([])
    const visible = ref(false)
    const gridTableEl = ref<IGridTable.Expose>()
    watch(() => props.value, () => {
      if (isArray(props.value)) {
        users.value = props.value
      }
    }, {
      immediate: true,
      deep: true
    })
    const tableAttrs = computed(():IGridTable.Props => ({
      api: {
        getList: async params => {
          const res = await systemApi.userList(params)
          return res.list
        },
      },
      rowStriped: 'none',
      size: 'small',
      showTableHeader: false,
      columnSchemas: [
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '联系电话',
          dataIndex: 'mobile'
        },
        {
          title: '部门',
          dataIndex: 'department.name'
        },
      ],
      searchSchemas: [
        {
          field: 'name',
          label: '姓名',
          component: 'Input',
          span: 9
        },
        {
          label: '所属部门',
          field: 'departmentIds',
          component: 'DepartmentPicker',
          span: 9
        } as IForm.Schema<'DepartmentPicker'>,
      ],
      getCheckboxProps: record => ({
        disabled: props.disableUser.includes(record.id)
      }),
      pagination: {
        pageSize: 10
      }
    }))

    if (props.value && props.value?.length > 0 && JSON.stringify(unref(users)) !== JSON.stringify(unref(props.value))) {
      users.value = cloneDeep(props.value)
    }


    const show = () => {
      visible.value = true
      nextTick(() => {
        console.log(users.value, 'users')

        gridTableEl.value?.setSelectedKeys(unref(users)?.map(item => item.id))
        gridTableEl.value?.setSelectedRows(unref(users))
      })
    }
    const updateValue = () => {
      emit('update:value', unref(users).length ? unref(users) : undefined)
    }
    const deleteUser = user => {
      users.value = unref(users).filter(item => item.id !== user.id)
      updateValue()
    }

    const handleOk = params => {
      users.value = gridTableEl.value?.getSelectionRows()
      updateValue()
      visible.value = false
    }

    expose({
      show
    })

    return () => <FormItemRest>
      {!props.hideTag && unref(users).map(user => (<Tag closable={true} onClose={() => deleteUser(user)}>{user.name}</Tag>))}
      {!props.hideTag && <Tag onClick={show} class="cursor-pointer">
        新增参与顾问
        <PlusOutlined></PlusOutlined>
      </Tag>}
      <Modal visible={visible.value} onCancel={() => {
        visible.value = false
      }} width={800} title={`添加${props.title}`} onOk={handleOk}>
        <GridTable ref={gridTableEl} {...tableAttrs.value}></GridTable>
      </Modal>
    </FormItemRest>
  }
})