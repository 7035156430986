<template>
  <div class="overflow-hidden bg-white">
    <!-- <div
      v-if="showTableTitle && (title || tableTitle)"
      class="table-title "
    >
      {{ tableTitle||title }}
    </div> -->
    <div class="px-5">
      <slot name="tabBar" />
      <TableTabs
        v-if="propsRef.tabsProps"
        v-bind="propsRef.tabsProps"
        @change="tabChangeFn"
      />
    </div>
    <!-- <div class="-pb-6"> 使table底部贴紧浏览器底部 -->
    <div
      v-if="propsRef.searchSchemas?.length"
      class="px-[12px] py-2 bg-white grid-table-search-bar"
      style="border-bottom: solid 1px #EFEFEF;"
    >
      <basic-form
        ref="baseFormRef"
        :is-search-form="true"
        :schemas="propsRef.searchSchemas"
        :collapsible="true"
        :reset-do-submit="true"
        :need-submit-on-enter="true"
        submit-text="搜索"
        @submit="search"
        @toggle-collapse="autoTableHeight"
      />
    </div>

    <div
      ref="tableWrapElRef"
      class="px-2 pt-1 bg-white"
    >
      <div
        v-if="propsRef.showTableHeader"
        class="my-2"
      >
        <table-header
          @create="handleCreate"
          @setFullscreen="setFullscreen"
        />
      </div>
      <table-body
        v-bind="bindAttrs"
        ref="tableBodyRef"
      >
        <template
          v-for="slot in Object.keys(slots)"
          #[slot]="data"
        >
          <slot
            :name="slot"
            v-bind="data || {}"
          />
        </template>
        <template
          v-if="isSummary && dataSourceRef.length > 0"
          #summary
        >
          <component :is="getSummary(dataSourceRef)" />
        </template>
      </table-body>
    </div>
  </div>
</template>

<script setup lang="ts">
import { createTableContext } from './hooks/context'
import { useDataSource } from './hooks/datasource'
import { useLoading } from './hooks/loading'
import { gridTableProps } from './props'
import { usePagination } from './hooks/pagination'
import { useColumn } from './hooks/column'
import { useSelection } from './hooks/selection'
import { useScroll } from './hooks/scroll'
import { omit } from 'lodash-es'
import BasicForm from '../form/basic-form.vue'
import TableBody from './components/table-body.vue'
import TableTabs from './components/table-tabs.vue'
import { useAction } from './hooks/action'

const emit = defineEmits<{
  (event: 'tabChange', ...value): void,
}>()
const tableWrapElRef = ref()
const tableBodyRef = ref<IGridTable.TableBodyExpose>()
const baseFormRef = ref<IForm.Expose>()
const attrs = useAttrs()
const slots = useSlots()
const props = defineProps(gridTableProps)
const dynamicProps = ref<Partial<IGridTable.Props>>()
const propsRef = computed(() => ({
  ...attrs,
  ...props,
  ...unref(dynamicProps),
} as IGridTable.Props))

const { setLoading, getLoading } = useLoading()
const { paginationRef, setPagination } = usePagination(propsRef)
const { selectedKeysRef, selectedRowsRef, resetSelection, selectionRef, setSelectedKeys, setSelectedRows } = useSelection(propsRef)
const { reload, search, tabChangeReload, rawDataSourceRef, dataSourceRef, totalRef, tabParamsRef, handleTableChange, tableKey } = useDataSource(propsRef, paginationRef, {
  setLoading,
  setPagination,
}, baseFormRef)
const { handleCreate, handleEdit, handleDel } = useAction(propsRef, reload)
const { columnsRef, scrollRef, setColumns, getColumns, getSummary, isSummary, autoColumnWidth, autoHeight } = useColumn(
  tableBodyRef,
  propsRef,
  paginationRef,
  rawDataSourceRef,
  {
    handleEdit,
    handleDel,
  }
)
const { setFullscreen } = useScroll(propsRef, tableBodyRef, columnsRef, scrollRef)
const bindAttrs = computed(() => ({
  rowKey: 'id',
  tableKey: propsRef.value.freshTableKey ? tableKey.value : '',
  ...omit(unref(propsRef), 'title'),
  loading: unref(getLoading),
  columns: unref(columnsRef),
  rowSelection: unref(selectionRef),
  pagination: unref(paginationRef),
  dataSource: unref(dataSourceRef),
  scroll: unref(scrollRef),
  onChange: (pagination, filters, sorter) => {
    handleTableChange(sorter)
    setPagination(pagination)
    reload()
  },
}), {
  onTrigger(e) {
    // 当 count.value 被修改时触发
    // console.log(e)
  }
})

const autoTableHeight = () => {
  nextTick(() => {
    autoHeight()
  })
}

onActivated(() => {
  if (!unref(getLoading) && unref(dataSourceRef).length > 0) {
    autoColumnWidth()
  }
})

watch(getLoading, v => {
  if (!v && unref(dataSourceRef)?.length > 0) {
    autoColumnWidth()
  }
})


function setProps(newProps: Partial<IGridTable.Props>) {
  dynamicProps.value = {
    ...unref(dynamicProps),
    ...newProps,
  }
}

const currentActiveTab = ref('')
const tabChangeFn = data => {
  tabChangeReload(data)
  emit('tabChange', data)
  currentActiveTab.value = data?.activeTab
}
const exposeData: IGridTable.Expose = {
  getActiveTab: () => currentActiveTab.value,
  wrapLlRef: tableWrapElRef,
  reload,
  setProps,
  getProps: () => propsRef,
  setLoading,
  getColumns,
  setColumns,
  autoColumnWidth,
  setPagination,
  paginationRef,
  getSearchParams: () => unref(baseFormRef.value?.formModel),
  getTabParams: () => unref(tabParamsRef),
  setSearchFormModel: data => {
    console.log(data, 'data')

    baseFormRef.value?.setFormModel(data)
  },
  resetSearchFormFields: () => {
    baseFormRef.value?.resetFields()
  },
  getDataSource: () => unref(dataSourceRef),
  getTotal: () => unref(totalRef),
  resetSelection,
  getSelectionKeys: () => unref(selectedKeysRef),
  getSelectionRows: () => unref(selectedRowsRef),
  setSelectedKeys,
  setSelectedRows,
  tableBodyRef: computed(() => unref(tableBodyRef.value?.tableBodyRef)),
  editingData: computed(() => tableBodyRef.value?.editingData),
  saveAllEditData: () => tableBodyRef.value?.saveAllEditData(),
  editAllEditData: () => tableBodyRef.value?.editAllEditData(),
  cancelAllEditData: () => tableBodyRef.value?.cancelAllEditData(),
}
defineExpose(exposeData)
createTableContext(exposeData)
</script>
