import { ExtractPropTypes } from 'vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import { Tag } from 'ant-design-vue/es'
import globalModelForm from '@/hooks/global-model-form'
import { useAppStore } from '@/store'
import { useMessage } from '@/hooks/message'
import { cloneDeep } from 'lodash-es'
const jobProps = {
  value: {
    type: Array
  }
}
export type JobManagerSelectProps = Partial<ExtractPropTypes<typeof jobProps>>

export default defineComponent({
  name: 'JobManagerSelect',
  inheritAttrs: false,
  props: jobProps,
  emits: ['update:value'],
  setup(props, ctx) {
    const { emit } = ctx
    // const userStore = useUserStore()
    const { getReferenceItemsValueMap, getReferenceItems, getReferenceConstantMap } = useAppStore()
    const users = ref<any>([])
    let userName = ''
    watch(() => props.value, v => {
      if (props.value && props.value?.length > 0 && JSON.stringify(unref(users)) !== JSON.stringify(unref(props.value))) {
        users.value = cloneDeep(props.value)
      }
    })


    // if (userStore.user) {
    //   users.value.push({
    //     user_id: userStore.user!.id,
    //     user_type: 1,
    //     name: userStore.user!.name
    //   })
    //   emit('update:value', unref(users))
    // }

    const createUser = () => {
      userName = ''
      globalModelForm.init({
        title: '新增执行团队',
        schemas: [
          {
            label: '成员类型',
            field: 'user_type',
            required: true,
            component: 'ReferenceSelect',
            componentProps: {
              referenceKey: 'executiveTeamUserNewTypeEnum'
            }
          } as IForm.Schema<'ReferenceSelect'>,
          {
            label: '职位顾问',
            field: 'user_id',
            required: true,
            component: 'UsersSelect',
            componentProps: {
              onChange: (v, record) => {
                userName = record?.label
              },
            }
          } as IForm.Schema<'UsersSelect'>,

        ],
        api: params => {
          if (users.value.some(item => item.user_id === params.user_id)) {
            useMessage.error('请勿重复添加成员')
            throw new Error('')
          }
          users.value.push({
            ...params,
            name: userName
          })
          emit('update:value', unref(users))
        }
      })
    }
    const deleteUser = user => {
      users.value = unref(users).filter(item => item.user_id !== user.user_id)
      emit('update:value', unref(users).length ? unref(users) : undefined)
    }
    return () => <div>
      {unref(users).map(user => (<Tag closable={true} onClose={() => deleteUser(user)}>{`${user.name}(${getReferenceItemsValueMap('executiveTeamUserTypeEnum')[user.user_type].label})`}</Tag>))}
      <Tag onClick={createUser} class="cursor-pointer">
        新增执行团队
        <PlusOutlined></PlusOutlined>
      </Tag>
    </div>
  }
})