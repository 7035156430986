import { ExtractPropTypes } from 'vue'
import type { TreeSelectProps } from 'ant-design-vue/es/tree-select'

export const deptTreeSelectProps = {
  apiParams: {
    type: Object,
    default: () => ({}),
  },
}

export type DeptTreeSelectProps = Partial<ExtractPropTypes<typeof deptTreeSelectProps>> & TreeSelectProps