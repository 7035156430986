import { useSiteConfig } from '@/hooks/site-config'
import { computed, unref, ComputedRef, Ref } from 'vue'
const { table } = useSiteConfig

export function useScroll(
  props: ComputedRef<IGridTable.Props>,
  tableElRef: Ref<IGridTable.TableBodyExpose | undefined>,
  columnsRef: ComputedRef<IGridTable.ColumnProps[]>,
  scrollRef: Ref,
) {
  const isFullscreen = ref(false)
  function setFullscreen(newValue: boolean) {
    isFullscreen.value = newValue
  }

  const getScrollX = computed(() => {
    let x = 0
    const { selectable, rowSelection, indexable, rowActions } = unref(props)
    const SELECT_BOX_WIDTH = 30 // 选择框宽度
    const INDEX_BOX_WIDTH = 50 // 索引列宽度
    const ACTION_BOX_WIDTH = 180 // 操作栏宽度
    if (selectable || rowSelection) {
      x += SELECT_BOX_WIDTH
    }
    if (indexable) {
      x += INDEX_BOX_WIDTH
    }
    if (rowActions && rowActions.length > 0) {
      x += ACTION_BOX_WIDTH
    }
    unref(columnsRef)
      .filter(column => !column.defaultHidden)
      .forEach(column => {
        x += column.width ? Number.parseInt(column.width as string) : table.defaultColumnWidth
      })
    const tableWidth = unref(tableElRef)?.tableBodyRef?.value?.$el.offsetWidth ?? 0
    return tableWidth > x ? '100%' : x
  })
  // 表格内容距离屏幕顶部的距离
  // const initTop = 460
  // // 表格距离底部屏幕底部的距离,越大，表格高度越小
  // const initBottom = 160
  // const yTop = ref(initTop)
  // onMounted(() => {
  //   const domTop = tableElRef.value?.$el?.getBoundingClientRect()?.top
  //   yTop.value = domTop ? parseInt(domTop) + initBottom : initTop
  //   if (yTop.value > (Math.floor(window.innerHeight / 2))) {
  //     // 如果yTop的值大于一半，就使用默认的,防止某些表格高度太低
  //     yTop.value = initTop
  //   }
  // })
  // let smallHeight = document.documentElement.clientHeight > 800
  // console.log(smallHeight, 'smallHeight')

  // const scrollRef = computed(() => {
  //   const { scroll } = unref(props)
  //   const tableScroll = {
  //     scrollToFirstRowOnChange: true,
  //     y: props.value?.needControlTableHeight && smallHeight ? `calc(100vh - ${yTop.value}px)` : undefined,
  //     x: unref(getScrollX),
  //     ...scroll,
  //   }
  //   if (isFullscreen.value) {
  //     tableScroll.y = 'calc(100vh - 90px)'
  //   }
  //   return tableScroll
  // })

  return {
    setFullscreen,
  }
}
