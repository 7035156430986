import { request } from '@/utils/request'

// 授权接口
const authApi = {

  // 钉钉code自动登录
  ddCodeLogin: (code: string) => request.post(`secretFreeLogin?code=${code}`),

  /** 钉钉登录 */
  ddLogin: (code: string) => request.post(`dingLogin?code=${code}`),

  /** 钉钉免密登录 */
  ddNoPasswordLogin: (code: string) => request.post(`/dingTalk/noSecret/login?code=${code}`),

  /** 检查 token 是否过期 */
  checkToken: () => request.get('checktoken', undefined, { withoutCheck: true }),

  /** 【】账号登录 */
  loginByAccount: (params: Recordable) => request.post('login', params),

  /** 【】账号登录 */
  loginCaptchaPictureSrc: '/api/admin/captcha/api',

  /** 手机号登录 */
  loginByMobile: (params: Recordable) => request.post('login/mobile', params),

  /** 重置密码 */
  resetPassword: (params: Recordable) => request.post('/admin_users/information', params),

  /** 重置密码 */
  resetPayPassword: (params: Recordable) => request.post('payPassword/reset', params),

  /** 登出 */
  logout: () => request.get('logout'),

  /** 获取用户信息 */
  getUserInfo: () => request.get('user/info'),

  /** 获取手机验证码 */
  getCaptcha: (params: Recordable) => request.post('captcha', params),

  // 注册
  registerAccount: (params: Recordable) => request.post('register', params),

  // 修改支付密码
  modifyPayPwd: (params: Recordable) => request.post('/adminUsers/modifyPayPassword', params)
}

export { authApi }


