import { ExtractPropTypes, PropType } from 'vue'
import type { UploadProps as AUploadProps, UploadFile } from 'ant-design-vue/es/upload'

export const uploadDraggerProps = {
  // 文件最大大小
  maxSize: {
    type: Number,
    default: 2,
  },
  // 最大文件数
  maxNum: {
    type: Number,
    default: 1,
  },
  // 是否可以拖拽上传
  isDragger: {
    type: Boolean,
    default: false
  },
  value: {
    type: Object as PropType<string[] | string>,
    default: () => [],
    // type: [
    //   Array as PropType<string[]>,
    //   String
    // ],
    // default: () => [],
  },
  autoUpload: {
    type: Boolean,
    default: true
  },
  valueIsId: {
    type: Boolean,
    default: false,
  },

  accept: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  onOriginResponseValue: {
    type: Function as PropType<(originFiles: UploadFile[]) => void>
  },
  acceptType: {
    type: String as PropType<'img' | 'file' | 'zip' | 'doc' | 'all'>,
    default: 'file',
  },

  // 上传组件提示信息
  remindInfo: {
    type: String
  },
  // 上传地址
  uploadUrl: {
    type: String,
    default: '/common/upload-img'
  },
  handleChangeFn: {
    type: Function as PropType<(data: any[], targetData:any) => void>
  },
  uploadClass: {
    type: String,
    default: 'h-[120px] flex flex-col justify-center'
  }
}

export type UploadDraggerProps = Partial<ExtractPropTypes<typeof uploadDraggerProps>> & AUploadProps
