<template>
  <div>
    <basic-modal
      v-bind="bindModalAttrs"
      v-model:visible="visible"
    >
      <div class="flex justify-between">
        <a-image
          shape="square"
          class="rounded"
          :src="detail?.avatar"
          :width="80"
          :height="80"
        />
        <div class="flex-1 ml-8">
          <span class="text-[22px] font-bold mb-1">{{ detail?.name }}</span>
          <span class="ml-2">
            <a-tag
              v-if="detail?.status == -1"
              color="red"
            >离职</a-tag>
            <a-tag
              v-else-if="detail?.status == 0"
              color="red"
            >冻结</a-tag>
            <a-tag
              v-else-if="detail?.status == 1"
              color="green"
            >激活</a-tag>
          </span>
          <basic-description
            :schemas="userInfoSchemas"
            :model="detail"
            :column="1"
          />
        </div>
      </div>
    </basic-modal>
    <div @click="showUserInfoModal">
      <slot>
        <div class="text-primaryColor-default cursor-pointer">
          {{ userName }}
        </div>
      </slot>
    </div>
  </div>
</template>
<script lang="tsx" setup>
import { userApi } from '@/api/user'
import { useUserStore } from '@/store'
import { BasicModalProps } from '@/components/modal/prop'
import { biz } from 'dingtalk-jsapi'
import { useMessage } from '@/hooks/message'
import { configDingJssdk, isInDingTalk } from '@/utils/dd'
import { isMobile } from '@/utils/utils'
import { useEnv } from '@/hooks/env'
import { getMoreTagColumn } from '@/schemas/common'
import {
  DingdingOutlined,
} from '@ant-design/icons-vue'
const { user } = useUserStore()
const props = defineProps({
  userId: {
    type: Number,
    default: undefined,
    required: true,
  },
  userName: {
    type: String,
    default: '--'
  }
})
const detail = ref(user)
const getDetail = async () => {
  detail.value = await userApi.getUserById(props.userId)
}
const showUserInfoModal = async () => {
  await getDetail()
  await configDingJssdk(location.href)
  visible.value = true
}
const corpid = useEnv.ddCropID
const ddTalkFn = () => {
  if (!isInDingTalk()) {
    return
  }
  if (detail.value?.ding_userid) {
    // 打开单个聊天，只支持移动端，PC不支持
    biz.chat.openSingleChat({
      corpId: corpid, // 企业id,必须是用户所属的企业的corpid
      userId: detail.value?.ding_userid, // 用户的uerid
      onSuccess(res) {
        // alert(`res${JSON.stringify(res)}`)
      },
      onFail(e) {
        // alert(`err${JSON.stringify(e)}`)
        useMessage.error(`${JSON.stringify(e)}`)
      }
    })
    // 打开群聊的，可PC和移动端
    // biz.chat.chooseConversationByCorpId({
    //   corpId: corpid, // 企业id,必须是用户所属的企业的corpid
    //   isAllowCreateGroup: false,
    //   filterNotOwnerGroup: false,
    //   onSuccess: res => {
    //     alert(`res${JSON.stringify(res)}`)
    //     biz.chat.toConversationByOpenConversationId({
    //       openConversationId: res?.openConversationId,
    //       onSuccess(result) {
    //         console.log(JSON.stringify(result))
    //       },
    //       onFail(err) {
    //         console.log(JSON.stringify(err))
    //       }
    //     })
    //   },
    //   onFail() {}
    // })
  } else {
    useMessage.info('用户钉钉id为空')
  }
}
const userInfoSchemas = computed((): IGridTable.ColumnProps[] => {
  let result = [
    {
      title: '所在部门',
      dataIndex: 'department.name',
    },
    {
      title: '直属领导',
      dataIndex: 'leader.name',
    },
    getMoreTagColumn('人员角色', 'roles', 'roles', 'name'),
    {
      title: '手机电话',
      dataIndex: 'mobile',
    }
  ]
  if (detail.value?.ding_userid && isInDingTalk() && isMobile()) {
    result.push({
      title: '钉钉对话',
      dataIndex: 'ddTalk',
      customRender: () => (<div onClick={() => {
        ddTalkFn()
      }} class="flex items-center justify-center cursor-pointer bg-[#0076f6] rounded w-5 h-5" id="ddTalkjumpButton">
        <DingdingOutlined style={{ color: '#ffffff' }} />
      </div>)
    } as any)
  }
  return result
})
const visible = ref(false)
const bindModalAttrs = computed((): BasicModalProps => ({
  title: '员工详情',
  width: 500,
  footer: false,
  destroyOnClose: true,
  onOk: () => {
    visible.value = false
  }
}))
</script>