<template>
  <custom-api-select
    :api="getList"
    :api-params="apiParams"
    :immediate="true"
    value-field="id"
    label-field="title"
  />
</template>
<script lang="ts" setup>
import { financeApi } from '@/api/finance'
import { useAppStore } from '@/store'
import { erpBankSelectProps } from './props'
const props = defineProps(erpBankSelectProps)
const { reference } = useAppStore()
const { apiParams } = props
const getList = () => (new Promise(async (resolve, reject) => {
  try {
    const res = await financeApi.getRefundBankList({})
    resolve(res.items ?? [])
  } catch {
    resolve([])
  }
}))
</script>
