import { ExtractPropTypes, PropType } from 'vue'
import { SelectProps } from 'ant-design-vue'
import {
  RangePickerProps,
} from 'ant-design-vue/es/date-picker'
// type SelectorTypes = keyof Reference.SelectorTypes
export interface ValueType{
      [key:string]: any
}
export const dateRangeSelectProps = {
  value: {
    type: [Object] as PropType<ValueType | undefined>,
  },
  referenceKey: {
    type: String as PropType<Reference.ReferenceKey>,
    default: () => 'dateFilterType'
  },
  valueField: {
    type: String,
    default: 'date'
  },
  rangeValueField: {
    type: String,
    default: 'range'
  },
  customValue: {
    type: [String, Number],
    default: 'custom'
  },
  dateRangePickerAttr: {
    type: Object as PropType<RangePickerProps>,
    default: () => ({})
  },
  selectAttr: {
    type: Object as PropType<SelectProps>,
    default: () => ({})
  },
  defaultType: {
    type: String,
    default: 'day'
  },
  dateRangeClass: {
    type: String,
    default: ''
  },
  selectClass: {
    type: String,
    default: 'w-[200px]'
  },
}

export type DateRangeSelectProps = Partial<ExtractPropTypes<typeof dateRangeSelectProps>>