export const regionsTree = [
  {
    value: 1,
    label: '北京市',
    children: [
      {
        value: 35,
        label: '东城区'
      }, {
        value: 36,
        label: '西城区'
      }, {
        value: 37,
        label: '崇文区'
      }, {
        value: 38,
        label: '宣武区'
      }, {
        value: 39,
        label: '朝阳区'
      }, {
        value: 40,
        label: '丰台区'
      }, {
        value: 41,
        label: '石景山区'
      }, {
        value: 42,
        label: '海淀区'
      }, {
        value: 43,
        label: '门头沟区'
      }, {
        value: 44,
        label: '房山区'
      }, {
        value: 45,
        label: '通州区'
      }, {
        value: 46,
        label: '顺义区'
      }, {
        value: 47,
        label: '昌平区'
      }, {
        value: 48,
        label: '大兴区'
      }, {
        value: 49,
        label: '怀柔区'
      }, {
        value: 50,
        label: '平谷区'
      }, {
        value: 51,
        label: '密云县'
      }, {
        value: 52,
        label: '延庆县'
      }
    ]
  }, {
    value: 2,
    label: '上海市',
    children: [
      {
        value: 53,
        label: '黄浦区'
      }, {
        value: 54,
        label: '卢湾区'
      }, {
        value: 55,
        label: '徐汇区'
      }, {
        value: 56,
        label: '长宁区'
      }, {
        value: 57,
        label: '静安区'
      }, {
        value: 58,
        label: '普陀区'
      }, {
        value: 59,
        label: '闸北区'
      }, {
        value: 60,
        label: '虹口区'
      }, {
        value: 61,
        label: '杨浦区'
      }, {
        value: 62,
        label: '闵行区'
      }, {
        value: 63,
        label: '宝山区'
      }, {
        value: 64,
        label: '嘉定区'
      }, {
        value: 65,
        label: '浦东新区'
      }, {
        value: 66,
        label: '金山区'
      }, {
        value: 67,
        label: '松江区'
      }, {
        value: 68,
        label: '青浦区'
      }, {
        value: 69,
        label: '南汇区'
      }, {
        value: 70,
        label: '奉贤区'
      }, {
        value: 71,
        label: '崇明县'
      }
    ]
  }, {
    value: 3,
    label: '天津市',
    children: [
      {
        value: 72,
        label: '和平区'
      }, {
        value: 73,
        label: '河东区'
      }, {
        value: 74,
        label: '河西区'
      }, {
        value: 75,
        label: '南开区'
      }, {
        value: 76,
        label: '河北区'
      }, {
        value: 77,
        label: '红桥区'
      }, {
        value: 78,
        label: '塘沽区'
      }, {
        value: 79,
        label: '汉沽区'
      }, {
        value: 80,
        label: '大港区'
      }, {
        value: 81,
        label: '东丽区'
      }, {
        value: 82,
        label: '西青区'
      }, {
        value: 83,
        label: '津南区'
      }, {
        value: 84,
        label: '北辰区'
      }, {
        value: 85,
        label: '武清区'
      }, {
        value: 86,
        label: '宝坻区'
      }, {
        value: 87,
        label: '宁河县'
      }, {
        value: 88,
        label: '静海县'
      }, {
        value: 89,
        label: '蓟县'
      }
    ]
  }, {
    value: 4,
    label: '重庆市',
    children: [
      {
        value: 90,
        label: '万州区'
      }, {
        value: 91,
        label: '涪陵区'
      }, {
        value: 92,
        label: '渝中区'
      }, {
        value: 93,
        label: '大渡口区'
      }, {
        value: 94,
        label: '江北区'
      }, {
        value: 95,
        label: '沙坪坝区'
      }, {
        value: 96,
        label: '九龙坡区'
      }, {
        value: 97,
        label: '南岸区'
      }, {
        value: 98,
        label: '北碚区'
      }, {
        value: 99,
        label: '万盛区'
      }, {
        value: 100,
        label: '双桥区'
      }, {
        value: 101,
        label: '渝北区'
      }, {
        value: 102,
        label: '巴南区'
      }, {
        value: 103,
        label: '黔江区'
      }, {
        value: 104,
        label: '长寿区'
      }, {
        value: 105,
        label: '綦江县'
      }, {
        value: 106,
        label: '潼南县'
      }, {
        value: 107,
        label: '铜梁县'
      }, {
        value: 108,
        label: '大足县'
      }, {
        value: 109,
        label: '荣昌县'
      }, {
        value: 110,
        label: '璧山县'
      }, {
        value: 111,
        label: '梁平县'
      }, {
        value: 112,
        label: '城口县'
      }, {
        value: 113,
        label: '丰都县'
      }, {
        value: 114,
        label: '垫江县'
      }, {
        value: 115,
        label: '武隆县'
      }, {
        value: 116,
        label: '忠县'
      }, {
        value: 117,
        label: '开县'
      }, {
        value: 118,
        label: '云阳县'
      }, {
        value: 119,
        label: '奉节县'
      }, {
        value: 120,
        label: '巫山县'
      }, {
        value: 121,
        label: '巫溪县'
      }, {
        value: 122,
        label: '石柱'
      }, {
        value: 123,
        label: '秀山'
      }, {
        value: 124,
        label: '酉阳'
      }, {
        value: 125,
        label: '彭水'
      }, {
        value: 126,
        label: '江津市'
      }, {
        value: 127,
        label: '合川市'
      }, {
        value: 128,
        label: '永川市'
      }, {
        value: 129,
        label: '南川市'
      }
    ]
  }, {
    value: 5,
    label: '河北省',
    children: [
      {
        value: 130,
        label: '石家庄市'
      }, {
        value: 131,
        label: '唐山市'
      }, {
        value: 132,
        label: '秦皇岛市'
      }, {
        value: 133,
        label: '邯郸市'
      }, {
        value: 134,
        label: '邢台市'
      }, {
        value: 135,
        label: '保定市'
      }, {
        value: 136,
        label: '张家口市'
      }, {
        value: 137,
        label: '承德市'
      }, {
        value: 138,
        label: '沧州市'
      }, {
        value: 139,
        label: '廊坊市'
      }, {
        value: 140,
        label: '衡水市'
      }
    ]
  }, {
    value: 6,
    label: '山西省',
    children: [
      {
        value: 141,
        label: '太原市'
      }, {
        value: 142,
        label: '大同市'
      }, {
        value: 143,
        label: '阳泉市'
      }, {
        value: 144,
        label: '长治市'
      }, {
        value: 145,
        label: '晋城市'
      }, {
        value: 146,
        label: '朔州市'
      }, {
        value: 147,
        label: '晋中市'
      }, {
        value: 148,
        label: '运城市'
      }, {
        value: 149,
        label: '忻州市'
      }, {
        value: 150,
        label: '临汾市'
      }, {
        value: 151,
        label: '吕梁市'
      }
    ]
  }, {
    value: 7,
    label: '内蒙古',
    children: [
      {
        value: 152,
        label: '呼和浩特市'
      }, {
        value: 153,
        label: '包头市'
      }, {
        value: 154,
        label: '乌海市'
      }, {
        value: 155,
        label: '赤峰市'
      }, {
        value: 156,
        label: '通辽市'
      }, {
        value: 157,
        label: '鄂尔多斯市'
      }, {
        value: 158,
        label: '呼伦贝尔市'
      }, {
        value: 159,
        label: '巴彦淖尔市'
      }, {
        value: 160,
        label: '乌兰察布市'
      }, {
        value: 161,
        label: '兴安盟'
      }, {
        value: 162,
        label: '锡林郭勒盟'
      }, {
        value: 163,
        label: '阿拉善盟'
      }
    ]
  }, {
    value: 8,
    label: '辽宁省',
    children: [
      {
        value: 164,
        label: '沈阳市'
      }, {
        value: 165,
        label: '大连市'
      }, {
        value: 166,
        label: '鞍山市'
      }, {
        value: 167,
        label: '抚顺市'
      }, {
        value: 168,
        label: '本溪市'
      }, {
        value: 169,
        label: '丹东市'
      }, {
        value: 170,
        label: '锦州市'
      }, {
        value: 171,
        label: '营口市'
      }, {
        value: 172,
        label: '阜新市'
      }, {
        value: 173,
        label: '辽阳市'
      }, {
        value: 174,
        label: '盘锦市'
      }, {
        value: 175,
        label: '铁岭市'
      }, {
        value: 176,
        label: '朝阳市'
      }, {
        value: 177,
        label: '葫芦岛市'
      }
    ]
  }, {
    value: 9,
    label: '吉林省',
    children: [
      {
        value: 178,
        label: '长春市'
      }, {
        value: 179,
        label: '吉林市'
      }, {
        value: 180,
        label: '四平市'
      }, {
        value: 181,
        label: '辽源市'
      }, {
        value: 182,
        label: '通化市'
      }, {
        value: 183,
        label: '白山市'
      }, {
        value: 184,
        label: '松原市'
      }, {
        value: 185,
        label: '白城市'
      }, {
        value: 186,
        label: '延边市'
      }
    ]
  }, {
    value: 10,
    label: '黑龙江省',
    children: [
      {
        value: 187,
        label: '哈尔滨市'
      }, {
        value: 188,
        label: '齐齐哈尔市'
      }, {
        value: 189,
        label: '鸡西市'
      }, {
        value: 190,
        label: '鹤岗市'
      }, {
        value: 191,
        label: '双鸭山市'
      }, {
        value: 192,
        label: '大庆市'
      }, {
        value: 193,
        label: '伊春市'
      }, {
        value: 194,
        label: '佳木斯市'
      }, {
        value: 195,
        label: '七台河市'
      }, {
        value: 196,
        label: '牡丹江市'
      }, {
        value: 197,
        label: '黑河市'
      }, {
        value: 198,
        label: '绥化市'
      }, {
        value: 199,
        label: '大兴安岭'
      }
    ]
  }, {
    value: 11,
    label: '江苏省',
    children: [
      {
        value: 200,
        label: '南京市'
      }, {
        value: 201,
        label: '无锡市'
      }, {
        value: 202,
        label: '徐州市'
      }, {
        value: 203,
        label: '常州市'
      }, {
        value: 204,
        label: '苏州市'
      }, {
        value: 205,
        label: '南通市'
      }, {
        value: 206,
        label: '连云港市'
      }, {
        value: 207,
        label: '淮安市'
      }, {
        value: 208,
        label: '盐城市'
      }, {
        value: 209,
        label: '扬州市'
      }, {
        value: 210,
        label: '镇江市'
      }, {
        value: 211,
        label: '泰州市'
      }, {
        value: 212,
        label: '宿迁市'
      }
    ]
  }, {
    value: 12,
    label: '浙江省',
    children: [
      {
        value: 213,
        label: '杭州市'
      }, {
        value: 214,
        label: '宁波市'
      }, {
        value: 215,
        label: '温州市'
      }, {
        value: 216,
        label: '嘉兴市'
      }, {
        value: 217,
        label: '湖州市'
      }, {
        value: 218,
        label: '绍兴市'
      }, {
        value: 219,
        label: '金华市'
      }, {
        value: 220,
        label: '衢州市'
      }, {
        value: 221,
        label: '舟山市'
      }, {
        value: 222,
        label: '台州市'
      }, {
        value: 223,
        label: '丽水市'
      }
    ]
  }, {
    value: 13,
    label: '安徽省',
    children: [
      {
        value: 224,
        label: '合肥市'
      }, {
        value: 225,
        label: '芜湖市'
      }, {
        value: 226,
        label: '蚌埠市'
      }, {
        value: 227,
        label: '淮南市'
      }, {
        value: 228,
        label: '马鞍山市'
      }, {
        value: 229,
        label: '淮北市'
      }, {
        value: 230,
        label: '铜陵市'
      }, {
        value: 231,
        label: '安庆市'
      }, {
        value: 232,
        label: '黄山市'
      }, {
        value: 233,
        label: '滁州市'
      }, {
        value: 234,
        label: '阜阳市'
      }, {
        value: 235,
        label: '宿州市'
      }, {
        value: 236,
        label: '巢湖市'
      }, {
        value: 237,
        label: '六安市'
      }, {
        value: 238,
        label: '亳州市'
      }, {
        value: 239,
        label: '池州市'
      }, {
        value: 240,
        label: '宣城市'
      }
    ]
  }, {
    value: 14,
    label: '福建省',
    children: [
      {
        value: 241,
        label: '福州市'
      }, {
        value: 242,
        label: '厦门市'
      }, {
        value: 243,
        label: '莆田市'
      }, {
        value: 244,
        label: '三明市'
      }, {
        value: 245,
        label: '泉州市'
      }, {
        value: 246,
        label: '漳州市'
      }, {
        value: 247,
        label: '南平市'
      }, {
        value: 248,
        label: '龙岩市'
      }, {
        value: 249,
        label: '宁德市'
      }
    ]
  }, {
    value: 15,
    label: '江西省',
    children: [
      {
        value: 250,
        label: '南昌市'
      }, {
        value: 251,
        label: '景德镇市'
      }, {
        value: 252,
        label: '萍乡市'
      }, {
        value: 253,
        label: '九江市'
      }, {
        value: 254,
        label: '新余市'
      }, {
        value: 255,
        label: '鹰潭市'
      }, {
        value: 256,
        label: '赣州市'
      }, {
        value: 257,
        label: '吉安市'
      }, {
        value: 258,
        label: '宜春市'
      }, {
        value: 259,
        label: '抚州市'
      }, {
        value: 260,
        label: '上饶市'
      }
    ]
  }, {
    value: 16,
    label: '山东省',
    children: [
      {
        value: 261,
        label: '济南市'
      }, {
        value: 262,
        label: '青岛市'
      }, {
        value: 263,
        label: '淄博市'
      }, {
        value: 264,
        label: '枣庄市'
      }, {
        value: 265,
        label: '东营市'
      }, {
        value: 266,
        label: '烟台市'
      }, {
        value: 267,
        label: '潍坊市'
      }, {
        value: 268,
        label: '济宁市'
      }, {
        value: 269,
        label: '泰安市'
      }, {
        value: 270,
        label: '威海市'
      }, {
        value: 271,
        label: '日照市'
      }, {
        value: 272,
        label: '莱芜市'
      }, {
        value: 273,
        label: '临沂市'
      }, {
        value: 274,
        label: '德州市'
      }, {
        value: 275,
        label: '聊城市'
      }, {
        value: 276,
        label: '滨州市'
      }, {
        value: 277,
        label: '荷泽市'
      }
    ]
  }, {
    value: 17,
    label: '河南省',
    children: [
      {
        value: 278,
        label: '郑州市'
      }, {
        value: 279,
        label: '开封市'
      }, {
        value: 280,
        label: '洛阳市'
      }, {
        value: 281,
        label: '平顶山市'
      }, {
        value: 282,
        label: '安阳市'
      }, {
        value: 283,
        label: '鹤壁市'
      }, {
        value: 284,
        label: '新乡市'
      }, {
        value: 285,
        label: '焦作市'
      }, {
        value: 286,
        label: '濮阳市'
      }, {
        value: 287,
        label: '许昌市'
      }, {
        value: 288,
        label: '漯河市'
      }, {
        value: 289,
        label: '三门峡市'
      }, {
        value: 290,
        label: '南阳市'
      }, {
        value: 291,
        label: '商丘市'
      }, {
        value: 292,
        label: '信阳市'
      }, {
        value: 293,
        label: '周口市'
      }, {
        value: 294,
        label: '驻马店市'
      }
    ]
  }, {
    value: 18,
    label: '湖北省',
    children: [
      {
        value: 295,
        label: '武汉市'
      }, {
        value: 296,
        label: '黄石市'
      }, {
        value: 297,
        label: '十堰市'
      }, {
        value: 298,
        label: '宜昌市'
      }, {
        value: 299,
        label: '襄樊市'
      }, {
        value: 300,
        label: '鄂州市'
      }, {
        value: 301,
        label: '荆门市'
      }, {
        value: 302,
        label: '孝感市'
      }, {
        value: 303,
        label: '荆州市'
      }, {
        value: 304,
        label: '黄冈市'
      }, {
        value: 305,
        label: '咸宁市'
      }, {
        value: 306,
        label: '随州市'
      }, {
        value: 307,
        label: '恩施市'
      }, {
        value: 308,
        label: '仙桃市'
      }, {
        value: 309,
        label: '潜江市'
      }, {
        value: 310,
        label: '天门市'
      }, {
        value: 311,
        label: '神农架林区'
      }
    ]
  }, {
    value: 19,
    label: '湖南省',
    children: [
      {
        value: 312,
        label: '长沙市'
      }, {
        value: 313,
        label: '株洲市'
      }, {
        value: 314,
        label: '湘潭市'
      }, {
        value: 315,
        label: '衡阳市'
      }, {
        value: 316,
        label: '邵阳市'
      }, {
        value: 317,
        label: '岳阳市'
      }, {
        value: 318,
        label: '常德市'
      }, {
        value: 319,
        label: '张家界市'
      }, {
        value: 320,
        label: '益阳市'
      }, {
        value: 321,
        label: '郴州市'
      }, {
        value: 322,
        label: '永州市'
      }, {
        value: 323,
        label: '怀化市'
      }, {
        value: 324,
        label: '娄底市'
      }, {
        value: 325,
        label: '湘西'
      }
    ]
  }, {
    value: 20,
    label: '广东省',
    children: [
      {
        value: 326,
        label: '广州市'
      }, {
        value: 327,
        label: '韶关市'
      }, {
        value: 328,
        label: '深圳市'
      }, {
        value: 329,
        label: '珠海市'
      }, {
        value: 330,
        label: '汕头市'
      }, {
        value: 331,
        label: '佛山市'
      }, {
        value: 332,
        label: '江门市'
      }, {
        value: 333,
        label: '湛江市'
      }, {
        value: 334,
        label: '茂名市'
      }, {
        value: 335,
        label: '肇庆市'
      }, {
        value: 336,
        label: '惠州市'
      }, {
        value: 337,
        label: '梅州市'
      }, {
        value: 338,
        label: '汕尾市'
      }, {
        value: 339,
        label: '河源市'
      }, {
        value: 340,
        label: '阳江市'
      }, {
        value: 341,
        label: '清远市'
      }, {
        value: 342,
        label: '东莞市'
      }, {
        value: 343,
        label: '中山市'
      }, {
        value: 344,
        label: '潮州市'
      }, {
        value: 345,
        label: '揭阳市'
      }, {
        value: 538,
        label: '云浮市'
      }
    ]
  }, {
    value: 21,
    label: '广西',
    children: [
      {
        value: 347,
        label: '南宁市'
      }, {
        value: 348,
        label: '柳州市'
      }, {
        value: 349,
        label: '桂林市'
      }, {
        value: 350,
        label: '梧州市'
      }, {
        value: 351,
        label: '北海市'
      }, {
        value: 352,
        label: '防城港市'
      }, {
        value: 353,
        label: '钦州市'
      }, {
        value: 354,
        label: '贵港市'
      }, {
        value: 355,
        label: '玉林市'
      }, {
        value: 356,
        label: '百色市'
      }, {
        value: 357,
        label: '贺州市'
      }, {
        value: 358,
        label: '河池市'
      }, {
        value: 359,
        label: '来宾市'
      }, {
        value: 360,
        label: '崇左市'
      }
    ]
  }, {
    value: 22,
    label: '海南省',
    children: [
      {
        value: 361,
        label: '海口市'
      }, {
        value: 362,
        label: '三亚市'
      }, {
        value: 363,
        label: '五指山市'
      }, {
        value: 364,
        label: '琼海市'
      }, {
        value: 365,
        label: '儋州市'
      }, {
        value: 366,
        label: '文昌市'
      }, {
        value: 367,
        label: '万宁市'
      }, {
        value: 368,
        label: '东方市'
      }, {
        value: 369,
        label: '定安县'
      }, {
        value: 370,
        label: '屯昌县'
      }, {
        value: 371,
        label: '澄迈县'
      }, {
        value: 372,
        label: '临高县'
      }, {
        value: 373,
        label: '白沙'
      }, {
        value: 374,
        label: '昌江'
      }, {
        value: 375,
        label: '乐东'
      }, {
        value: 376,
        label: '陵水'
      }, {
        value: 377,
        label: '保亭'
      }, {
        value: 378,
        label: '琼中'
      }, {
        value: 379,
        label: '西沙群岛'
      }, {
        value: 380,
        label: '南沙群岛'
      }, {
        value: 381,
        label: '中沙群岛'
      }
    ]
  }, {
    value: 23,
    label: '四川省',
    children: [
      {
        value: 382,
        label: '成都市'
      }, {
        value: 383,
        label: '自贡市'
      }, {
        value: 384,
        label: '攀枝花市'
      }, {
        value: 385,
        label: '泸州市'
      }, {
        value: 386,
        label: '德阳市'
      }, {
        value: 387,
        label: '绵阳市'
      }, {
        value: 388,
        label: '广元市'
      }, {
        value: 389,
        label: '遂宁市'
      }, {
        value: 390,
        label: '内江市'
      }, {
        value: 391,
        label: '乐山市'
      }, {
        value: 392,
        label: '南充市'
      }, {
        value: 393,
        label: '眉山市'
      }, {
        value: 394,
        label: '宜宾市'
      }, {
        value: 395,
        label: '广安市'
      }, {
        value: 396,
        label: '达州市'
      }, {
        value: 397,
        label: '雅安市'
      }, {
        value: 398,
        label: '巴中市'
      }, {
        value: 399,
        label: '资阳市'
      }, {
        value: 400,
        label: '阿坝'
      }, {
        value: 401,
        label: '甘孜'
      }, {
        value: 402,
        label: '凉山'
      }
    ]
  }, {
    value: 24,
    label: '贵州省',
    children: [
      {
        value: 403,
        label: '贵阳市'
      }, {
        value: 404,
        label: '六盘水市'
      }, {
        value: 405,
        label: '遵义市'
      }, {
        value: 406,
        label: '安顺市'
      }, {
        value: 407,
        label: '铜仁地区'
      }, {
        value: 408,
        label: '黔西南州'
      }, {
        value: 409,
        label: '毕节地区'
      }, {
        value: 410,
        label: '黔东南州'
      }, {
        value: 411,
        label: '黔南州'
      }
    ]
  }, {
    value: 25,
    label: '云南省',
    children: [
      {
        value: 412,
        label: '昆明市'
      }, {
        value: 413,
        label: '曲靖市'
      }, {
        value: 414,
        label: '玉溪市'
      }, {
        value: 415,
        label: '保山市'
      }, {
        value: 416,
        label: '昭通市'
      }, {
        value: 417,
        label: '丽江市'
      }, {
        value: 418,
        label: '思茅市'
      }, {
        value: 419,
        label: '临沧市'
      }, {
        value: 420,
        label: '楚雄'
      }, {
        value: 421,
        label: '红河州'
      }, {
        value: 422,
        label: '文山州'
      }, {
        value: 423,
        label: '西双版纳州'
      }, {
        value: 424,
        label: '大理州'
      }, {
        value: 425,
        label: '德宏州'
      }, {
        value: 426,
        label: '怒江州'
      }, {
        value: 427,
        label: '迪庆州'
      }
    ]
  }, {
    value: 26,
    label: '西藏',
    children: [
      {
        value: 428,
        label: '拉萨市'
      }, {
        value: 429,
        label: '昌都地区'
      }, {
        value: 430,
        label: '山南地区'
      }, {
        value: 431,
        label: '日喀则地区'
      }, {
        value: 432,
        label: '那曲地区'
      }, {
        value: 433,
        label: '阿里地区'
      }, {
        value: 434,
        label: '林芝地区'
      }
    ]
  }, {
    value: 27,
    label: '陕西省',
    children: [
      {
        value: 435,
        label: '西安市'
      }, {
        value: 436,
        label: '铜川市'
      }, {
        value: 437,
        label: '宝鸡市'
      }, {
        value: 438,
        label: '咸阳市'
      }, {
        value: 439,
        label: '渭南市'
      }, {
        value: 440,
        label: '延安市'
      }, {
        value: 441,
        label: '汉中市'
      }, {
        value: 442,
        label: '榆林市'
      }, {
        value: 443,
        label: '安康市'
      }, {
        value: 444,
        label: '商洛市'
      }
    ]
  }, {
    value: 28,
    label: '甘肃省',
    children: [
      {
        value: 445,
        label: '兰州市'
      }, {
        value: 446,
        label: '嘉峪关市'
      }, {
        value: 447,
        label: '金昌市'
      }, {
        value: 448,
        label: '白银市'
      }, {
        value: 449,
        label: '天水市'
      }, {
        value: 450,
        label: '武威市'
      }, {
        value: 451,
        label: '张掖市'
      }, {
        value: 452,
        label: '平凉市'
      }, {
        value: 453,
        label: '酒泉市'
      }, {
        value: 454,
        label: '庆阳市'
      }, {
        value: 455,
        label: '定西市'
      }, {
        value: 456,
        label: '陇南市'
      }, {
        value: 457,
        label: '临夏'
      }, {
        value: 458,
        label: '甘南'
      }
    ]
  }, {
    value: 29,
    label: '青海省',
    children: [
      {
        value: 459,
        label: '西宁市'
      }, {
        value: 460,
        label: '海东地区'
      }, {
        value: 461,
        label: '海北州'
      }, {
        value: 462,
        label: '黄南州'
      }, {
        value: 463,
        label: '海南州'
      }, {
        value: 464,
        label: '果洛州'
      }, {
        value: 465,
        label: '玉树州'
      }, {
        value: 466,
        label: '海西州'
      }
    ]
  }, {
    value: 30,
    label: '宁夏',
    children: [
      {
        value: 467,
        label: '银川市'
      }, {
        value: 468,
        label: '石嘴山市'
      }, {
        value: 469,
        label: '吴忠市'
      }, {
        value: 470,
        label: '固原市'
      }, {
        value: 471,
        label: '中卫市'
      }
    ]
  }, {
    value: 31,
    label: '新疆',
    children: [
      {
        value: 472,
        label: '乌鲁木齐市'
      }, {
        value: 473,
        label: '克拉玛依市'
      }, {
        value: 474,
        label: '吐鲁番地区'
      }, {
        value: 475,
        label: '哈密地区'
      }, {
        value: 476,
        label: '昌吉州'
      }, {
        value: 477,
        label: '博州'
      }, {
        value: 478,
        label: '巴州'
      }, {
        value: 479,
        label: '阿克苏地区'
      }, {
        value: 480,
        label: '克州'
      }, {
        value: 481,
        label: '喀什地区'
      }, {
        value: 482,
        label: '和田地区'
      }, {
        value: 483,
        label: '伊犁州'
      }, {
        value: 484,
        label: '塔城地区'
      }, {
        value: 485,
        label: '阿勒泰地区'
      }, {
        value: 486,
        label: '石河子市'
      }, {
        value: 487,
        label: '阿拉尔市'
      }, {
        value: 488,
        label: '图木舒克市'
      }, {
        value: 489,
        label: '五家渠市'
      }
    ]
  }, {
    value: 32,
    label: '台湾省',
    children: [
      {
        value: 490,
        label: '台北市'
      }, {
        value: 491,
        label: '高雄市'
      }, {
        value: 492,
        label: '基隆市'
      }, {
        value: 493,
        label: '新竹市'
      }, {
        value: 494,
        label: '台中市'
      }, {
        value: 495,
        label: '嘉义市'
      }, {
        value: 496,
        label: '台南市'
      }, {
        value: 497,
        label: '台北县'
      }, {
        value: 498,
        label: '桃园县'
      }, {
        value: 499,
        label: '新竹县'
      }, {
        value: 500,
        label: '苗栗县'
      }, {
        value: 501,
        label: '台中县'
      }, {
        value: 502,
        label: '彰化县'
      }, {
        value: 503,
        label: '南投县'
      }, {
        value: 504,
        label: '云林县'
      }, {
        value: 505,
        label: '嘉义县'
      }, {
        value: 506,
        label: '台南县'
      }, {
        value: 507,
        label: '高雄县'
      }, {
        value: 508,
        label: '屏东县'
      }, {
        value: 509,
        label: '宜兰县'
      }, {
        value: 510,
        label: '花莲县'
      }, {
        value: 511,
        label: '台东县'
      }, {
        value: 512,
        label: '澎湖县'
      }, {
        value: 513,
        label: '金门县'
      }, {
        value: 514,
        label: '连江县'
      }
    ]
  }, {
    value: 33,
    label: '香港',
    children: [
      {
        value: 515,
        label: '中西区'
      }, {
        value: 516,
        label: '东区'
      }, {
        value: 517,
        label: '南区'
      }, {
        value: 518,
        label: '湾仔区'
      }, {
        value: 519,
        label: '九龙城区'
      }, {
        value: 520,
        label: '观塘区'
      }, {
        value: 521,
        label: '深水埗区'
      }, {
        value: 522,
        label: '黄大仙区'
      }, {
        value: 523,
        label: '油尖旺区'
      }, {
        value: 524,
        label: '离岛区'
      }, {
        value: 525,
        label: '葵青区'
      }, {
        value: 526,
        label: '北区'
      }, {
        value: 527,
        label: '西贡区'
      }, {
        value: 528,
        label: '沙田区'
      }, {
        value: 529,
        label: '大埔区'
      }, {
        value: 530,
        label: '荃湾区'
      }, {
        value: 531,
        label: '屯门区'
      }, {
        value: 532,
        label: '元朗区'
      }
    ]
  }, {
    value: 34,
    label: '澳门',
    children: [
      {
        value: 533,
        label: '花地玛堂区'
      }, {
        value: 534,
        label: '圣安多尼堂区'
      }, {
        value: 535,
        label: '大堂区'
      }, {
        value: 536,
        label: '望德堂区'
      }, {
        value: 537,
        label: '风顺堂区'
      }
    ]
  }
]

export const regionsTreeWithoutArea = [
  {
    value: 1,
    label: '北京市'
  }, {
    value: 2,
    label: '上海市'
  }, {
    value: 3,
    label: '天津市'
  }, {
    value: 4,
    label: '重庆市'
  }, {
    value: 5,
    label: '河北省',
    children: [
      {
        value: 130,
        label: '石家庄市'
      }, {
        value: 131,
        label: '唐山市'
      }, {
        value: 132,
        label: '秦皇岛市'
      }, {
        value: 133,
        label: '邯郸市'
      }, {
        value: 134,
        label: '邢台市'
      }, {
        value: 135,
        label: '保定市'
      }, {
        value: 136,
        label: '张家口市'
      }, {
        value: 137,
        label: '承德市'
      }, {
        value: 138,
        label: '沧州市'
      }, {
        value: 139,
        label: '廊坊市'
      }, {
        value: 140,
        label: '衡水市'
      }
    ]
  }, {
    value: 6,
    label: '山西省',
    children: [
      {
        value: 141,
        label: '太原市'
      }, {
        value: 142,
        label: '大同市'
      }, {
        value: 143,
        label: '阳泉市'
      }, {
        value: 144,
        label: '长治市'
      }, {
        value: 145,
        label: '晋城市'
      }, {
        value: 146,
        label: '朔州市'
      }, {
        value: 147,
        label: '晋中市'
      }, {
        value: 148,
        label: '运城市'
      }, {
        value: 149,
        label: '忻州市'
      }, {
        value: 150,
        label: '临汾市'
      }, {
        value: 151,
        label: '吕梁市'
      }
    ]
  }, {
    value: 7,
    label: '内蒙古',
    children: [
      {
        value: 152,
        label: '呼和浩特市'
      }, {
        value: 153,
        label: '包头市'
      }, {
        value: 154,
        label: '乌海市'
      }, {
        value: 155,
        label: '赤峰市'
      }, {
        value: 156,
        label: '通辽市'
      }, {
        value: 157,
        label: '鄂尔多斯市'
      }, {
        value: 158,
        label: '呼伦贝尔市'
      }, {
        value: 159,
        label: '巴彦淖尔市'
      }, {
        value: 160,
        label: '乌兰察布市'
      }, {
        value: 161,
        label: '兴安盟'
      }, {
        value: 162,
        label: '锡林郭勒盟'
      }, {
        value: 163,
        label: '阿拉善盟'
      }
    ]
  }, {
    value: 8,
    label: '辽宁省',
    children: [
      {
        value: 164,
        label: '沈阳市'
      }, {
        value: 165,
        label: '大连市'
      }, {
        value: 166,
        label: '鞍山市'
      }, {
        value: 167,
        label: '抚顺市'
      }, {
        value: 168,
        label: '本溪市'
      }, {
        value: 169,
        label: '丹东市'
      }, {
        value: 170,
        label: '锦州市'
      }, {
        value: 171,
        label: '营口市'
      }, {
        value: 172,
        label: '阜新市'
      }, {
        value: 173,
        label: '辽阳市'
      }, {
        value: 174,
        label: '盘锦市'
      }, {
        value: 175,
        label: '铁岭市'
      }, {
        value: 176,
        label: '朝阳市'
      }, {
        value: 177,
        label: '葫芦岛市'
      }
    ]
  }, {
    value: 9,
    label: '吉林省',
    children: [
      {
        value: 178,
        label: '长春市'
      }, {
        value: 179,
        label: '吉林市'
      }, {
        value: 180,
        label: '四平市'
      }, {
        value: 181,
        label: '辽源市'
      }, {
        value: 182,
        label: '通化市'
      }, {
        value: 183,
        label: '白山市'
      }, {
        value: 184,
        label: '松原市'
      }, {
        value: 185,
        label: '白城市'
      }, {
        value: 186,
        label: '延边市'
      }
    ]
  }, {
    value: 10,
    label: '黑龙江省',
    children: [
      {
        value: 187,
        label: '哈尔滨市'
      }, {
        value: 188,
        label: '齐齐哈尔市'
      }, {
        value: 189,
        label: '鸡西市'
      }, {
        value: 190,
        label: '鹤岗市'
      }, {
        value: 191,
        label: '双鸭山市'
      }, {
        value: 192,
        label: '大庆市'
      }, {
        value: 193,
        label: '伊春市'
      }, {
        value: 194,
        label: '佳木斯市'
      }, {
        value: 195,
        label: '七台河市'
      }, {
        value: 196,
        label: '牡丹江市'
      }, {
        value: 197,
        label: '黑河市'
      }, {
        value: 198,
        label: '绥化市'
      }, {
        value: 199,
        label: '大兴安岭'
      }
    ]
  }, {
    value: 11,
    label: '江苏省',
    children: [
      {
        value: 200,
        label: '南京市'
      }, {
        value: 201,
        label: '无锡市'
      }, {
        value: 202,
        label: '徐州市'
      }, {
        value: 203,
        label: '常州市'
      }, {
        value: 204,
        label: '苏州市'
      }, {
        value: 205,
        label: '南通市'
      }, {
        value: 206,
        label: '连云港市'
      }, {
        value: 207,
        label: '淮安市'
      }, {
        value: 208,
        label: '盐城市'
      }, {
        value: 209,
        label: '扬州市'
      }, {
        value: 210,
        label: '镇江市'
      }, {
        value: 211,
        label: '泰州市'
      }, {
        value: 212,
        label: '宿迁市'
      }
    ]
  }, {
    value: 12,
    label: '浙江省',
    children: [
      {
        value: 213,
        label: '杭州市'
      }, {
        value: 214,
        label: '宁波市'
      }, {
        value: 215,
        label: '温州市'
      }, {
        value: 216,
        label: '嘉兴市'
      }, {
        value: 217,
        label: '湖州市'
      }, {
        value: 218,
        label: '绍兴市'
      }, {
        value: 219,
        label: '金华市'
      }, {
        value: 220,
        label: '衢州市'
      }, {
        value: 221,
        label: '舟山市'
      }, {
        value: 222,
        label: '台州市'
      }, {
        value: 223,
        label: '丽水市'
      }
    ]
  }, {
    value: 13,
    label: '安徽省',
    children: [
      {
        value: 224,
        label: '合肥市'
      }, {
        value: 225,
        label: '芜湖市'
      }, {
        value: 226,
        label: '蚌埠市'
      }, {
        value: 227,
        label: '淮南市'
      }, {
        value: 228,
        label: '马鞍山市'
      }, {
        value: 229,
        label: '淮北市'
      }, {
        value: 230,
        label: '铜陵市'
      }, {
        value: 231,
        label: '安庆市'
      }, {
        value: 232,
        label: '黄山市'
      }, {
        value: 233,
        label: '滁州市'
      }, {
        value: 234,
        label: '阜阳市'
      }, {
        value: 235,
        label: '宿州市'
      }, {
        value: 236,
        label: '巢湖市'
      }, {
        value: 237,
        label: '六安市'
      }, {
        value: 238,
        label: '亳州市'
      }, {
        value: 239,
        label: '池州市'
      }, {
        value: 240,
        label: '宣城市'
      }
    ]
  }, {
    value: 14,
    label: '福建省',
    children: [
      {
        value: 241,
        label: '福州市'
      }, {
        value: 242,
        label: '厦门市'
      }, {
        value: 243,
        label: '莆田市'
      }, {
        value: 244,
        label: '三明市'
      }, {
        value: 245,
        label: '泉州市'
      }, {
        value: 246,
        label: '漳州市'
      }, {
        value: 247,
        label: '南平市'
      }, {
        value: 248,
        label: '龙岩市'
      }, {
        value: 249,
        label: '宁德市'
      }
    ]
  }, {
    value: 15,
    label: '江西省',
    children: [
      {
        value: 250,
        label: '南昌市'
      }, {
        value: 251,
        label: '景德镇市'
      }, {
        value: 252,
        label: '萍乡市'
      }, {
        value: 253,
        label: '九江市'
      }, {
        value: 254,
        label: '新余市'
      }, {
        value: 255,
        label: '鹰潭市'
      }, {
        value: 256,
        label: '赣州市'
      }, {
        value: 257,
        label: '吉安市'
      }, {
        value: 258,
        label: '宜春市'
      }, {
        value: 259,
        label: '抚州市'
      }, {
        value: 260,
        label: '上饶市'
      }
    ]
  }, {
    value: 16,
    label: '山东省',
    children: [
      {
        value: 261,
        label: '济南市'
      }, {
        value: 262,
        label: '青岛市'
      }, {
        value: 263,
        label: '淄博市'
      }, {
        value: 264,
        label: '枣庄市'
      }, {
        value: 265,
        label: '东营市'
      }, {
        value: 266,
        label: '烟台市'
      }, {
        value: 267,
        label: '潍坊市'
      }, {
        value: 268,
        label: '济宁市'
      }, {
        value: 269,
        label: '泰安市'
      }, {
        value: 270,
        label: '威海市'
      }, {
        value: 271,
        label: '日照市'
      }, {
        value: 272,
        label: '莱芜市'
      }, {
        value: 273,
        label: '临沂市'
      }, {
        value: 274,
        label: '德州市'
      }, {
        value: 275,
        label: '聊城市'
      }, {
        value: 276,
        label: '滨州市'
      }, {
        value: 277,
        label: '荷泽市'
      }
    ]
  }, {
    value: 17,
    label: '河南省',
    children: [
      {
        value: 278,
        label: '郑州市'
      }, {
        value: 279,
        label: '开封市'
      }, {
        value: 280,
        label: '洛阳市'
      }, {
        value: 281,
        label: '平顶山市'
      }, {
        value: 282,
        label: '安阳市'
      }, {
        value: 283,
        label: '鹤壁市'
      }, {
        value: 284,
        label: '新乡市'
      }, {
        value: 285,
        label: '焦作市'
      }, {
        value: 286,
        label: '濮阳市'
      }, {
        value: 287,
        label: '许昌市'
      }, {
        value: 288,
        label: '漯河市'
      }, {
        value: 289,
        label: '三门峡市'
      }, {
        value: 290,
        label: '南阳市'
      }, {
        value: 291,
        label: '商丘市'
      }, {
        value: 292,
        label: '信阳市'
      }, {
        value: 293,
        label: '周口市'
      }, {
        value: 294,
        label: '驻马店市'
      }
    ]
  }, {
    value: 18,
    label: '湖北省',
    children: [
      {
        value: 295,
        label: '武汉市'
      }, {
        value: 296,
        label: '黄石市'
      }, {
        value: 297,
        label: '十堰市'
      }, {
        value: 298,
        label: '宜昌市'
      }, {
        value: 299,
        label: '襄樊市'
      }, {
        value: 300,
        label: '鄂州市'
      }, {
        value: 301,
        label: '荆门市'
      }, {
        value: 302,
        label: '孝感市'
      }, {
        value: 303,
        label: '荆州市'
      }, {
        value: 304,
        label: '黄冈市'
      }, {
        value: 305,
        label: '咸宁市'
      }, {
        value: 306,
        label: '随州市'
      }, {
        value: 307,
        label: '恩施市'
      }, {
        value: 308,
        label: '仙桃市'
      }, {
        value: 309,
        label: '潜江市'
      }, {
        value: 310,
        label: '天门市'
      }, {
        value: 311,
        label: '神农架林区'
      }
    ]
  }, {
    value: 19,
    label: '湖南省',
    children: [
      {
        value: 312,
        label: '长沙市'
      }, {
        value: 313,
        label: '株洲市'
      }, {
        value: 314,
        label: '湘潭市'
      }, {
        value: 315,
        label: '衡阳市'
      }, {
        value: 316,
        label: '邵阳市'
      }, {
        value: 317,
        label: '岳阳市'
      }, {
        value: 318,
        label: '常德市'
      }, {
        value: 319,
        label: '张家界市'
      }, {
        value: 320,
        label: '益阳市'
      }, {
        value: 321,
        label: '郴州市'
      }, {
        value: 322,
        label: '永州市'
      }, {
        value: 323,
        label: '怀化市'
      }, {
        value: 324,
        label: '娄底市'
      }, {
        value: 325,
        label: '湘西'
      }
    ]
  }, {
    value: 20,
    label: '广东省',
    children: [
      {
        value: 326,
        label: '广州市'
      }, {
        value: 327,
        label: '韶关市'
      }, {
        value: 328,
        label: '深圳市'
      }, {
        value: 329,
        label: '珠海市'
      }, {
        value: 330,
        label: '汕头市'
      }, {
        value: 331,
        label: '佛山市'
      }, {
        value: 332,
        label: '江门市'
      }, {
        value: 333,
        label: '湛江市'
      }, {
        value: 334,
        label: '茂名市'
      }, {
        value: 335,
        label: '肇庆市'
      }, {
        value: 336,
        label: '惠州市'
      }, {
        value: 337,
        label: '梅州市'
      }, {
        value: 338,
        label: '汕尾市'
      }, {
        value: 339,
        label: '河源市'
      }, {
        value: 340,
        label: '阳江市'
      }, {
        value: 341,
        label: '清远市'
      }, {
        value: 342,
        label: '东莞市'
      }, {
        value: 343,
        label: '中山市'
      }, {
        value: 344,
        label: '潮州市'
      }, {
        value: 345,
        label: '揭阳市'
      }, {
        value: 538,
        label: '云浮市'
      }
    ]
  }, {
    value: 21,
    label: '广西',
    children: [
      {
        value: 347,
        label: '南宁市'
      }, {
        value: 348,
        label: '柳州市'
      }, {
        value: 349,
        label: '桂林市'
      }, {
        value: 350,
        label: '梧州市'
      }, {
        value: 351,
        label: '北海市'
      }, {
        value: 352,
        label: '防城港市'
      }, {
        value: 353,
        label: '钦州市'
      }, {
        value: 354,
        label: '贵港市'
      }, {
        value: 355,
        label: '玉林市'
      }, {
        value: 356,
        label: '百色市'
      }, {
        value: 357,
        label: '贺州市'
      }, {
        value: 358,
        label: '河池市'
      }, {
        value: 359,
        label: '来宾市'
      }, {
        value: 360,
        label: '崇左市'
      }
    ]
  }, {
    value: 22,
    label: '海南省',
    children: [
      {
        value: 361,
        label: '海口市'
      }, {
        value: 362,
        label: '三亚市'
      }, {
        value: 363,
        label: '五指山市'
      }, {
        value: 364,
        label: '琼海市'
      }, {
        value: 365,
        label: '儋州市'
      }, {
        value: 366,
        label: '文昌市'
      }, {
        value: 367,
        label: '万宁市'
      }, {
        value: 368,
        label: '东方市'
      }, {
        value: 369,
        label: '定安县'
      }, {
        value: 370,
        label: '屯昌县'
      }, {
        value: 371,
        label: '澄迈县'
      }, {
        value: 372,
        label: '临高县'
      }, {
        value: 373,
        label: '白沙'
      }, {
        value: 374,
        label: '昌江'
      }, {
        value: 375,
        label: '乐东'
      }, {
        value: 376,
        label: '陵水'
      }, {
        value: 377,
        label: '保亭'
      }, {
        value: 378,
        label: '琼中'
      }, {
        value: 379,
        label: '西沙群岛'
      }, {
        value: 380,
        label: '南沙群岛'
      }, {
        value: 381,
        label: '中沙群岛'
      }
    ]
  }, {
    value: 23,
    label: '四川省',
    children: [
      {
        value: 382,
        label: '成都市'
      }, {
        value: 383,
        label: '自贡市'
      }, {
        value: 384,
        label: '攀枝花市'
      }, {
        value: 385,
        label: '泸州市'
      }, {
        value: 386,
        label: '德阳市'
      }, {
        value: 387,
        label: '绵阳市'
      }, {
        value: 388,
        label: '广元市'
      }, {
        value: 389,
        label: '遂宁市'
      }, {
        value: 390,
        label: '内江市'
      }, {
        value: 391,
        label: '乐山市'
      }, {
        value: 392,
        label: '南充市'
      }, {
        value: 393,
        label: '眉山市'
      }, {
        value: 394,
        label: '宜宾市'
      }, {
        value: 395,
        label: '广安市'
      }, {
        value: 396,
        label: '达州市'
      }, {
        value: 397,
        label: '雅安市'
      }, {
        value: 398,
        label: '巴中市'
      }, {
        value: 399,
        label: '资阳市'
      }, {
        value: 400,
        label: '阿坝'
      }, {
        value: 401,
        label: '甘孜'
      }, {
        value: 402,
        label: '凉山'
      }
    ]
  }, {
    value: 24,
    label: '贵州省',
    children: [
      {
        value: 403,
        label: '贵阳市'
      }, {
        value: 404,
        label: '六盘水市'
      }, {
        value: 405,
        label: '遵义市'
      }, {
        value: 406,
        label: '安顺市'
      }, {
        value: 407,
        label: '铜仁地区'
      }, {
        value: 408,
        label: '黔西南州'
      }, {
        value: 409,
        label: '毕节地区'
      }, {
        value: 410,
        label: '黔东南州'
      }, {
        value: 411,
        label: '黔南州'
      }
    ]
  }, {
    value: 25,
    label: '云南省',
    children: [
      {
        value: 412,
        label: '昆明市'
      }, {
        value: 413,
        label: '曲靖市'
      }, {
        value: 414,
        label: '玉溪市'
      }, {
        value: 415,
        label: '保山市'
      }, {
        value: 416,
        label: '昭通市'
      }, {
        value: 417,
        label: '丽江市'
      }, {
        value: 418,
        label: '思茅市'
      }, {
        value: 419,
        label: '临沧市'
      }, {
        value: 420,
        label: '楚雄'
      }, {
        value: 421,
        label: '红河州'
      }, {
        value: 422,
        label: '文山州'
      }, {
        value: 423,
        label: '西双版纳州'
      }, {
        value: 424,
        label: '大理州'
      }, {
        value: 425,
        label: '德宏州'
      }, {
        value: 426,
        label: '怒江州'
      }, {
        value: 427,
        label: '迪庆州'
      }
    ]
  }, {
    value: 26,
    label: '西藏',
    children: [
      {
        value: 428,
        label: '拉萨市'
      }, {
        value: 429,
        label: '昌都地区'
      }, {
        value: 430,
        label: '山南地区'
      }, {
        value: 431,
        label: '日喀则地区'
      }, {
        value: 432,
        label: '那曲地区'
      }, {
        value: 433,
        label: '阿里地区'
      }, {
        value: 434,
        label: '林芝地区'
      }
    ]
  }, {
    value: 27,
    label: '陕西省',
    children: [
      {
        value: 435,
        label: '西安市'
      }, {
        value: 436,
        label: '铜川市'
      }, {
        value: 437,
        label: '宝鸡市'
      }, {
        value: 438,
        label: '咸阳市'
      }, {
        value: 439,
        label: '渭南市'
      }, {
        value: 440,
        label: '延安市'
      }, {
        value: 441,
        label: '汉中市'
      }, {
        value: 442,
        label: '榆林市'
      }, {
        value: 443,
        label: '安康市'
      }, {
        value: 444,
        label: '商洛市'
      }
    ]
  }, {
    value: 28,
    label: '甘肃省',
    children: [
      {
        value: 445,
        label: '兰州市'
      }, {
        value: 446,
        label: '嘉峪关市'
      }, {
        value: 447,
        label: '金昌市'
      }, {
        value: 448,
        label: '白银市'
      }, {
        value: 449,
        label: '天水市'
      }, {
        value: 450,
        label: '武威市'
      }, {
        value: 451,
        label: '张掖市'
      }, {
        value: 452,
        label: '平凉市'
      }, {
        value: 453,
        label: '酒泉市'
      }, {
        value: 454,
        label: '庆阳市'
      }, {
        value: 455,
        label: '定西市'
      }, {
        value: 456,
        label: '陇南市'
      }, {
        value: 457,
        label: '临夏'
      }, {
        value: 458,
        label: '甘南'
      }
    ]
  }, {
    value: 29,
    label: '青海省',
    children: [
      {
        value: 459,
        label: '西宁市'
      }, {
        value: 460,
        label: '海东地区'
      }, {
        value: 461,
        label: '海北州'
      }, {
        value: 462,
        label: '黄南州'
      }, {
        value: 463,
        label: '海南州'
      }, {
        value: 464,
        label: '果洛州'
      }, {
        value: 465,
        label: '玉树州'
      }, {
        value: 466,
        label: '海西州'
      }
    ]
  }, {
    value: 30,
    label: '宁夏',
    children: [
      {
        value: 467,
        label: '银川市'
      }, {
        value: 468,
        label: '石嘴山市'
      }, {
        value: 469,
        label: '吴忠市'
      }, {
        value: 470,
        label: '固原市'
      }, {
        value: 471,
        label: '中卫市'
      }
    ]
  }, {
    value: 31,
    label: '新疆',
    children: [
      {
        value: 472,
        label: '乌鲁木齐市'
      }, {
        value: 473,
        label: '克拉玛依市'
      }, {
        value: 474,
        label: '吐鲁番地区'
      }, {
        value: 475,
        label: '哈密地区'
      }, {
        value: 476,
        label: '昌吉州'
      }, {
        value: 477,
        label: '博州'
      }, {
        value: 478,
        label: '巴州'
      }, {
        value: 479,
        label: '阿克苏地区'
      }, {
        value: 480,
        label: '克州'
      }, {
        value: 481,
        label: '喀什地区'
      }, {
        value: 482,
        label: '和田地区'
      }, {
        value: 483,
        label: '伊犁州'
      }, {
        value: 484,
        label: '塔城地区'
      }, {
        value: 485,
        label: '阿勒泰地区'
      }, {
        value: 486,
        label: '石河子市'
      }, {
        value: 487,
        label: '阿拉尔市'
      }, {
        value: 488,
        label: '图木舒克市'
      }, {
        value: 489,
        label: '五家渠市'
      }
    ]
  }, {
    value: 32,
    label: '台湾省',
    children: [
      {
        value: 490,
        label: '台北市'
      }, {
        value: 491,
        label: '高雄市'
      }, {
        value: 492,
        label: '基隆市'
      }, {
        value: 493,
        label: '新竹市'
      }, {
        value: 494,
        label: '台中市'
      }, {
        value: 495,
        label: '嘉义市'
      }, {
        value: 496,
        label: '台南市'
      }, {
        value: 497,
        label: '台北县'
      }, {
        value: 498,
        label: '桃园县'
      }, {
        value: 499,
        label: '新竹县'
      }, {
        value: 500,
        label: '苗栗县'
      }, {
        value: 501,
        label: '台中县'
      }, {
        value: 502,
        label: '彰化县'
      }, {
        value: 503,
        label: '南投县'
      }, {
        value: 504,
        label: '云林县'
      }, {
        value: 505,
        label: '嘉义县'
      }, {
        value: 506,
        label: '台南县'
      }, {
        value: 507,
        label: '高雄县'
      }, {
        value: 508,
        label: '屏东县'
      }, {
        value: 509,
        label: '宜兰县'
      }, {
        value: 510,
        label: '花莲县'
      }, {
        value: 511,
        label: '台东县'
      }, {
        value: 512,
        label: '澎湖县'
      }, {
        value: 513,
        label: '金门县'
      }, {
        value: 514,
        label: '连江县'
      }
    ]
  }, {
    value: 33,
    label: '香港',
    children: [
      {
        value: 515,
        label: '中西区'
      }, {
        value: 516,
        label: '东区'
      }, {
        value: 517,
        label: '南区'
      }, {
        value: 518,
        label: '湾仔区'
      }, {
        value: 519,
        label: '九龙城区'
      }, {
        value: 520,
        label: '观塘区'
      }, {
        value: 521,
        label: '深水埗区'
      }, {
        value: 522,
        label: '黄大仙区'
      }, {
        value: 523,
        label: '油尖旺区'
      }, {
        value: 524,
        label: '离岛区'
      }, {
        value: 525,
        label: '葵青区'
      }, {
        value: 526,
        label: '北区'
      }, {
        value: 527,
        label: '西贡区'
      }, {
        value: 528,
        label: '沙田区'
      }, {
        value: 529,
        label: '大埔区'
      }, {
        value: 530,
        label: '荃湾区'
      }, {
        value: 531,
        label: '屯门区'
      }, {
        value: 532,
        label: '元朗区'
      }
    ]
  }, {
    value: 34,
    label: '澳门',
    children: [
      {
        value: 533,
        label: '花地玛堂区'
      }, {
        value: 534,
        label: '圣安多尼堂区'
      }, {
        value: 535,
        label: '大堂区'
      }, {
        value: 536,
        label: '望德堂区'
      }, {
        value: 537,
        label: '风顺堂区'
      }
    ]
  }
]


export const regions = [
  {
    id: 1,
    parent_id: 0,
    name: '北京市',
    sons: 18,
    sort: 0
  }, {
    id: 2,
    parent_id: 0,
    name: '上海市',
    sons: 19,
    sort: 0
  }, {
    id: 3,
    parent_id: 0,
    name: '天津市',
    sons: 18,
    sort: 0
  }, {
    id: 4,
    parent_id: 0,
    name: '重庆市',
    sons: 40,
    sort: 0
  }, {
    id: 5,
    parent_id: 0,
    name: '河北省',
    sons: 11,
    sort: 0
  }, {
    id: 6,
    parent_id: 0,
    name: '山西省',
    sons: 11,
    sort: 0
  }, {
    id: 7,
    parent_id: 0,
    name: '内蒙古',
    sons: 12,
    sort: 0
  }, {
    id: 8,
    parent_id: 0,
    name: '辽宁省',
    sons: 14,
    sort: 0
  }, {
    id: 9,
    parent_id: 0,
    name: '吉林省',
    sons: 9,
    sort: 0
  }, {
    id: 10,
    parent_id: 0,
    name: '黑龙江省',
    sons: 13,
    sort: 0
  }, {
    id: 11,
    parent_id: 0,
    name: '江苏省',
    sons: 13,
    sort: 0
  }, {
    id: 12,
    parent_id: 0,
    name: '浙江省',
    sons: 11,
    sort: 0
  }, {
    id: 13,
    parent_id: 0,
    name: '安徽省',
    sons: 17,
    sort: 0
  }, {
    id: 14,
    parent_id: 0,
    name: '福建省',
    sons: 9,
    sort: 0
  }, {
    id: 15,
    parent_id: 0,
    name: '江西省',
    sons: 11,
    sort: 0
  }, {
    id: 16,
    parent_id: 0,
    name: '山东省',
    sons: 17,
    sort: 0
  }, {
    id: 17,
    parent_id: 0,
    name: '河南省',
    sons: 17,
    sort: 0
  }, {
    id: 18,
    parent_id: 0,
    name: '湖北省',
    sons: 17,
    sort: 0
  }, {
    id: 19,
    parent_id: 0,
    name: '湖南省',
    sons: 14,
    sort: 0
  }, {
    id: 20,
    parent_id: 0,
    name: '广东省',
    sons: 20,
    sort: 0
  }, {
    id: 21,
    parent_id: 0,
    name: '广西',
    sons: 14,
    sort: 0
  }, {
    id: 22,
    parent_id: 0,
    name: '海南省',
    sons: 21,
    sort: 0
  }, {
    id: 23,
    parent_id: 0,
    name: '四川省',
    sons: 21,
    sort: 0
  }, {
    id: 24,
    parent_id: 0,
    name: '贵州省',
    sons: 9,
    sort: 0
  }, {
    id: 25,
    parent_id: 0,
    name: '云南省',
    sons: 16,
    sort: 0
  }, {
    id: 26,
    parent_id: 0,
    name: '西藏',
    sons: 7,
    sort: 0
  }, {
    id: 27,
    parent_id: 0,
    name: '陕西省',
    sons: 10,
    sort: 0
  }, {
    id: 28,
    parent_id: 0,
    name: '甘肃省',
    sons: 14,
    sort: 0
  }, {
    id: 29,
    parent_id: 0,
    name: '青海省',
    sons: 8,
    sort: 0
  }, {
    id: 30,
    parent_id: 0,
    name: '宁夏',
    sons: 5,
    sort: 0
  }, {
    id: 31,
    parent_id: 0,
    name: '新疆',
    sons: 18,
    sort: 0
  }, {
    id: 32,
    parent_id: 0,
    name: '台湾省',
    sons: 25,
    sort: 0
  }, {
    id: 33,
    parent_id: 0,
    name: '香港',
    sons: 18,
    sort: 0
  }, {
    id: 34,
    parent_id: 0,
    name: '澳门',
    sons: 5,
    sort: 0
  }
]