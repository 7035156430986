import { ExtractPropTypes, PropType } from 'vue'
import { DescriptionsProps } from 'ant-design-vue/es'

export const basicDescriptionProps = {
  // 数据模型
  schemas: {
    type: Array as PropType<IGridTable.ColumnProps[]>
  },

  // 数据
  model: {
    type: Object as PropType<Recordable>
  },

  // 下内边距
  paddingBottom: {
    type: Number
  },

  // 是否要 0 值转变 --
  zeroToLine: {
    type: Boolean,
    default: true
  },


  // 内容样式 css驼峰样式 fontSize
  contentStyle: {
    type: Object as PropType<Recordable>,
    default: {}
  },

  // 描述样式
  labelStyle: {
    type: Object as PropType<Recordable>,
    default: {}
  },

  // 省略号
  isEllipsis: {
    type: Boolean,
    default: false
  },
  needHightLight: {
    type: Boolean,
    default: false,
  },
  // 是否需要处理换行问题,将\n的效果显示出来
  handleLine: {
    type: Boolean,
    default: false
  }
}

export type DescItem = {
  label: string;
  value: string | number;
}

export type BasicDescriptionProps = Partial<ExtractPropTypes<typeof basicDescriptionProps>> & DescriptionsProps