<template>
  <a-dropdown>
    <a-tooltip>
      <a-button
        type="primary"
        style="margin-left: 5px;"
      >
        高亮
      </a-button>
    </a-tooltip>
    <template #overlay>
      <a-menu @click="handleClick">
        <a-menu-item
          v-for="menu in getReferenceItems('hightlightLocalEnum')"
          :key="menu.key"
        >
          <div class="flex items-center">
            <div
              v-if="menu.color || menu.key=='clear'"
              class="w-[25px] h-[25px] mr-[10px]"
              :style="{backgroundColor:`#${menu.color}`}"
            />
            <div>{{ menu.label }}</div>
          </div>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>
<script lang="ts" setup>
import { isFunction } from 'lodash-es'
import { useTableContext } from '../hooks/context'
import { HighlightActionProps } from '../props'
import { useMessage } from '@/hooks/message'
import { useAppStore } from '../../../store/modules/app'
const props = defineProps(HighlightActionProps)
const { getReferenceItems } = useAppStore()

type EmitEvents = {
  (e: 'change', ...value): void,
}
const emits = defineEmits<EmitEvents>()
const table = useTableContext()

const isSelectRow = computed(() => table.getSelectionKeys()?.length > 0)
const currentSelectMenu = ref('')
async function handleClick(e) {
  currentSelectMenu.value = e.key
  if (table.getSelectionKeys()?.length === 0) {
    useMessage.info('请选择数据！')
    return
  }
  const selectRowKeys = table.getSelectionKeys()
  const findItem = getReferenceItems('hightlightLocalEnum').find(item => item.key === e.key)
  if (findItem && selectRowKeys?.length > 0) {
    emits('change', findItem)
    if (isFunction(props.changeApi)) {
      await props.changeApi(findItem)
    }
  }
}
</script>
