import { request } from '@/utils/request'
export const resumeApi = {
  // 黑名单列表
  getResumeBlacklist: data => request.get('resume/getResumeBlacklist', data),
  // 加入黑名单
  addBlacklist: data => request.post('resume/addBlacklist', data),
  // 取消加入黑名单
  cancelBlacklist: data => request.post('resume/cancelBlacklist', data),
  // 人才报告
  getResumeEvaluates: data => request.get('resume/evaluates', data),
  // 删除简历的某个分类
  delResumeBindCategory: data => request.post('resumeCategory/delResumeBindCategory', data),
  // 取消收藏简历
  cancelCollectResume: data => request.delete('resume/delCollect', data),
  // 收藏简历
  collectResume: data => request.post('resume/collect', data),
  // 浏览记录
  getResumeViewLog: data => request.get('resume/viewLog/index', data),
  // 简历来源列表
  getResumeSources: data => request.get('common/sources', data),
  // 添加简历来源
  addResumeSource: data => request.post('resume/addResumeSource', data),
  // 删除简历来源
  delResumeSource: id => request.post(`resume/delResumeSource/${id}`),
  // 编辑简历来源
  updateResumeSource: (id, data) => request.post(`resume/updateResumeSource/${id}`, data),
  // 设置简历背景色
  resumeColor: data => request.post('resume/color', data),
  // 简历列表
  resumeList: data => request.get('resumes', data),

  // 简历列表
  resumeMyList: data => request.get('resumes/myList', data),
  // 简历详情
  resumeDetail: id => request.get(`resumes/${id}`),
  // 更新简历
  updateUser: data => request.post('resume/update-user', data),
  // 添加简历
  addResume: data => request.post('resumes', data),
  // 修改简历
  updateResume: data => request.put(`resumes/${data.id}`, data),
  // 删除简历
  destroyResume: id => request.post('resume/destroy', { id }),
  // 更新职业意向
  updateIntention: params => request.post('resume/updateIntention', params),
  // 工作经历列表
  getWorks: resume_id => request.get('resumeWorks', { resume_id }),
  // 添加工作经历
  addWork: data => request.post('resumeWorks', data),
  // 更改工作经历
  updateWork: data => request.put(`resumeWorks/${data.id}`, data),
  // 删除工作经历
  delWork: id => request.delete(`resumeWorks/${id}`),

  // 教育经历列表
  getEdus: resume_id => request.get('resumeEdus', { resume_id }),
  // 添加教育经历
  addEdu: data => request.post('resumeEdus', data),
  // 更改教育经历
  updateEdu: data => request.put(`resumeEdus/${data.id}`, data),
  // 删除教育经历
  delEdu: id => request.delete(`resumeEdus/${id}`),

  // 项目经历列表
  getProjects: resume_id => request.get('resumeProjects', { resume_id }),
  // 添加项目经历
  addProject: data => request.post('resumeProjects', data),
  // 更改项目经历
  updateProject: data => request.put(`resumeProjects/${data.id}`, data),
  // 删除项目经历
  delProject: id => request.delete(`resumeProjects/${id}`),

  // 技能列表
  getSkills: resume_id => request.get('resumeSkills', { resume_id }),
  // 添加技能
  addSkill: data => request.post('resumeSkills', data),
  // 更改技能
  updateSkill: data => request.put(`resumeSkills/${data.id}`, data),
  // 删除技能
  delSkill: id => request.delete(`resumeSkills/${id}`),

  // 语言列表
  getLanguages: resume_id => request.get('resumeLanguages', { resume_id }),
  // 添加语言
  addLanguage: data => request.post('resumeLanguages', data),
  // 更改语言
  updateLanguage: data => request.put(`resumeLanguages/${data.id}`, data),
  // 删除语言
  delLanguage: id => request.delete(`resumeLanguages/${id}`),

  // 附件列表
  getAttachments: data => request.get('resume/attachmentList', data),
  // 添加附件
  addAttachment: data => request.post('resume/addAttachment', data, { disableThrottling: true }),
  // 删除附件
  delAttachment: data => request.post('resume/delAttachment', data),
  // 备注列表
  remarkList: data => request.get('resumeRemarks', data),
  // 添加备注
  addRemark: data => request.post('resumeRemarks', data),
  // 删除备注
  delRemark: data => request.delete(`resumeRemarks/${data.id}`, data),

  // 评价列表
  getEvaluates: resume_id => request.get('resume/evaluates', { resume_id }),

  // 添加推荐评价
  addEvaluate: data => request.post('resume/add-evaluate', data),

  // 修改推荐评价
  updateEvaluate: data => request.post('resume/update-evaluate', data),

  // 上传解析简历
  addResumeFile: data => request.upload('resumes/import', data),

  // 下载简历文件(Word)
  downloadResumeFile: data => request.get('resume/download-resume-file', data),

  // 获取简历操作记录
  getResumeLog: data => request.get('resume/getResumeLog', data),

  // 获取简历文件列表
  getAttachmentsList: data => request.get('resumes/uploadList', data),

  // 删除简历文件
  destroyResumeFile(id) {
    const data = { id }
    return request.post('resume/attachment-destroy', data)
  },
  // 人才备注
  getResumeRemark: data => request.get('resumeRemark/index', data),

  // 重新上传简历文件
  reUploadResumeFile: data => request.post('resume/attachment-re-upload', data)
}
