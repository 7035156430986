<style scoped lang="less">
.ant-input-number-affix-wrapper {
  width: 100%;
}
</style>
<script lang="tsx">
import { Col, Form, FormItemProps, Tooltip } from 'ant-design-vue/es'
import { omit, pick, isFunction, cloneDeep } from 'lodash-es'
import { computed, defineComponent, PropType } from 'vue'
import { componentTypeMap } from './component-type-map'
import { useFormContext } from '../hooks/context'
import { createDefaultMsg, createSchemaRule } from '../hooks/rule'


export default defineComponent({
  props: {
    dynamicRules: {
      type: Array,
      default: () => []
    },
    // 是否是动态表单
    isDynamic: {
      type: Boolean,
      default: false
    },
    // 动态表单数组下标
    dynamicIndex: {
      type: [Number, String],
    },
    // 动态表单的父字段
    dynamicParentField: {
      type: String,
      default: ''
    },

    schema: {
      type: Object as PropType<IForm.Schema>,
      required: true,
    },
    formProps: {
      type: Object as PropType<IForm.Props>,
      default: () => ({}),
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:dynamicValue'],
  setup(props, { emit }) {
    const formContext = useFormContext()
    // 是否允许折叠
    const isNotCollapsed = computed(() => !props.schema.collapsible || !props.collapsed)

    // 是否显示
    const isShow = computed((): boolean => {
      const { isShow = true } = props.schema

      if (isFunction(isShow)) {
        let schemaParams = {
          schema: props.schema,
          field: props.schema.field!,
          getValues: () => cloneDeep(formContext.formModel),
          dynamicIndex: props.dynamicIndex,
        } as any
        if (props.isDynamic && props.dynamicParentField && props.dynamicIndex) {
          schemaParams = {
            ...schemaParams,
            values: { ...formContext.formModel[props.dynamicParentField][props.dynamicIndex] },
            dynamicParentField: props.dynamicParentField,
            dynamicIndex: props.dynamicIndex,
            formModel: { ...formContext.formModel }
          }
        } else {
          schemaParams.values = { ...formContext.formModel }
        }
        return isShow(schemaParams)
      } else {
        return isShow
      }
      // return isFunction(isShow) ? isShow({
      //   schema: props.schema,
      //   values: formModel,
      //   field: props.schema.field!
      // }) : isShow
    })

    // 操作项数据更新回调函数
    function handleUpdateValue(value) {
      if (props.isDynamic) {
        // emits('update:dynamicValue', value)
        emit('update:dynamicValue', value)
      } else {

        formContext.setFormModel({ [props.schema.field!]: value })
        formContext.validate([props.schema.field!])
      }
    }

    let span = props.isDynamic
      ? props.schema.span ?? props.formProps.baseItemCol?.span
      : props.schema.span

    // col 容器配置
    const colAttrs = computed(() => ({
      ...pick(props.schema, ['span']),
      span,
    }))


    // item 提示文字配置
    const tipsAttrs = computed(() => ({
      show: props.schema.prefixTips?.isShowTips || false,
      class: props.schema.prefixTips?.tipsClass,
      content: props.schema.prefixTips?.content || '默认提示'

    }))
    const toolTipsAttrs = computed(() => ({
      isShow: props.schema.suffixTips?.isShow || false,
      tooltipsProps: props.schema.suffixTips?.tooltipsProps,
      content: props.schema.suffixTips?.content || '默认后缀'

    }))

    // componentProps抽取required是否表单必填
    let { componentProps } = props.schema
    // 组件的属性是否为外部回调函数
    if (isFunction(componentProps)) {
      let compParams = {
        schema: props.schema,
        values: { ...formContext.formModel },
        getValues: () => cloneDeep(formContext.formModel),
        field: props.schema.field,
        actions: omit(formContext, ['formModel']),
      } as any

      if (props.isDynamic && props.dynamicParentField && props.dynamicIndex) {
        compParams = {
          ...compParams,
          values: { ...formContext.formModel[props.dynamicParentField][props.dynamicIndex] },
          dynamicParentField: props.dynamicParentField,
          dynamicIndex: props.dynamicIndex
        }
      }

      componentProps = componentProps(compParams)
    }

    // componentProps?.required 控制必填 非必填
    if (componentProps?.required !== undefined && typeof componentProps?.required === 'boolean') {
      props.schema!.required = componentProps?.required
    }

    const getHelpMessage = computed(() => {
      const { helpMessage, label } = props.schema
      const result = isFunction(helpMessage) ? helpMessage({
        values: { ...formContext.formModel },
        getValues: () => cloneDeep(formContext.formModel)
      }) : helpMessage
      return result ? (
        <Tooltip title={result} class="text-gray-600 ml-1 text-[13px]">
          <question-circle-outlined />
        </Tooltip>
      ) : (
        ''
      )
    })

    const getLabel = computed(() => {
      if (!props.schema.label) {
        return ''
      }
      let label = isFunction(props.schema?.label) ? props.schema?.label({
        getValues: () => cloneDeep(formContext.formModel)
      }) : props.schema?.label
      return <div class="flex items-center">
        {label}
        {unref(getHelpMessage)}
      </div>
    })


    // formItem 属性配置
    const formItemAttrs = computed(() => {

      const { formProps } = props
      const labelWidth = formProps.labelWidth ? `${formProps.labelWidth}px` : '100px'


      return {
        name: props.isDynamic ? props.dynamicRules : props.schema.field,
        rules: createSchemaRule(props.schema),
        labelCol: { style: { width: formProps.layout !== 'vertical' ? labelWidth : '100%' } },
        wrapperCol: { style: { width: formProps.layout !== 'vertical' ? `calc(100% - ${labelWidth})` : '100%' } },
        ...omit(props.schema, ['name', 'component', 'componentProps', 'defaultValue', 'span', 'rules', 'required']),
        validateTrigger: '',
        label: unref(getLabel)
      } as FormItemProps
    })

    // 监听 formModel（即本组件对应的 model 值变化）的变化，重置组件内容，如重置组件的 value 等属性
    const comAttrs = computed(() => {
      let { component: componentName } = props.schema

      // 已抽取到外部,提取required
      // 组件的属性是否为外部回调函数
      // if (isFunction(componentProps)) {
      //   componentProps = componentProps({
      //     schema: props.schema,
      //     values: { ...formContext.formModel },
      //     field: props.schema.field,
      //     actions: omit(formContext, ['formModel']),
      //   })
      // }


      // 检查本组件是否是勾选类型的组件
      const isCheckComponent = componentName && ['Switch', 'Checkbox'].includes(componentName)
      let formItemComAttrs = {
        allowClear: true, // 是否允许清除
        placeholder: createDefaultMsg(props.schema), // placeholder 提示语
        fieldName: props.schema.field, // 字段名
        ...componentProps, // 其他配置项
        disabled: formContext.isReadOnly.value || componentProps?.disabled, // todo
        [isCheckComponent ? 'checked' : 'value']: setValue(),
        [isCheckComponent ? 'onUpdate:checked' : 'onUpdate:value']: handleUpdateValue,
      }

      // 动态表单获取表单配置项
      if (props.schema.component === 'DynamicForm') {
        formItemComAttrs.formProps = Object.assign(formItemComAttrs, props.formProps)
      }
      return formItemComAttrs
    })
    function setValue() {
      if (props.dynamicIndex !== undefined && props.dynamicParentField) {
        return formContext.formModel[props.dynamicParentField][props.dynamicIndex][props.schema.field]
      } else {
        return formContext.formModel[props.schema.backfillField || props.schema.field!] // 监听 form model 中，本组件的数据变化，重置组件的值
      }
    }

    // 渲染具体组件的函数
    function renderCom() {
      const { component = 'Input', customComponent, renderComponentContent } = props.schema
      let Com: ReturnType<typeof defineComponent> = null
      if (customComponent) {
        Com = toRaw(customComponent) // 转化为普通对象，避免响应式依赖，频繁变动
      } else {
        Com = componentTypeMap[component] as ReturnType<typeof defineComponent>
      }

      return renderComponentContent ? (
        <Com {...comAttrs.value} v-slots={renderComponentContent}></Com>
      ) : (
        <Com {...comAttrs.value} />

      )
    }

    return () => (
      isShow.value && (
        <Col {...colAttrs.value} v-show={isNotCollapsed.value}>
          <div v-show={tipsAttrs.value.show} class={`absolute  text-red-500 ${tipsAttrs.value.class} `}>{tipsAttrs.value.content} </div>
          <Form.Item {...formItemAttrs.value}>{renderCom()}</Form.Item>
          {toolTipsAttrs.value.isShow && <Tooltip {...toolTipsAttrs.value.tooltipsProps}>{toolTipsAttrs.value.content}</Tooltip>}
          {props.schema.formItemRenderContent && props.schema.formItemRenderContent()}
        </Col>
      )
    )
  },
})
</script>
