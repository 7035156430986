<template>
  <a-config-provider
    :locale="zhCN"
    :transform-cell-text="({ text }) => !isEmpty(text) ? text : ' '"
  >
    <div>
      <router-view v-if="isRouteActive" />
      <a-spin v-else />
      <modal-form
        v-if="globalModelForm._isRender"
        v-bind="(globalModelForm.modelFormAttr as Readonly<ExtractPropTypes<typeof modalFormProps>>)"
        :ref="el => globalModelForm.modelFromRef = el"
        :visible="globalModelForm.visible"
        @ok="() => globalModelForm.visible = false"
        @cancel="() => globalModelForm.visible = false"
      />
    </div>
  </a-config-provider>
</template>
<script lang="ts" setup>
import type { ExtractPropTypes } from 'vue'
import { modalFormProps } from '@/components/form/props'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import ModalForm from './components/form/modal-form.vue'
import { useWindowSizeFn } from './hooks/window-size-fn'
import globalModelForm from '@/hooks/global-model-form'
// eslint-disable-next-line no-duplicate-imports
import { provide, nextTick, onMounted } from 'vue'
import { env } from 'dingtalk-jsapi'
import { router } from '@/router'

import { useSystemStore } from './store/modules/system'
import { isEmpty } from './utils/is'
const systemStore = useSystemStore()

const setDevider = () => {
  const { body } = document
  const rect = body.getBoundingClientRect()
  const isMobile = rect.width - 1 < 992
  const miniScreen = rect.width - 1 < 1280
  systemStore.toggleDevice(!isMobile)
  systemStore.setMiniScrren(miniScreen)
}
useWindowSizeFn(setDevider)
setDevider()


const isRouteActive = ref(true)
provide('refresh', () => {
  isRouteActive.value = false
  nextTick(() => {
    isRouteActive.value = true
  })
})

onMounted(() => {
  if (env.platform !== 'notInDingTalk') {
    router.replace({ name: 'ddLogin' })
  } else {
    console.log('非钉钉环境1')
  }
})

</script>