<template>
  <ApiTreeSelect
    v-bind="bindAttrs"
    :dropdown-match-select-width="300"
    show-checked-strategy="SHOW_ALL"
    value-field="id"
    label-field="name"
  />
</template>
<script lang="ts" setup>
import { selectorApi } from '@/api/selector'
import { computed } from 'vue'

const parentValue = computed(() => undefined)
const bindAttrs = computed(() => ({
  api: selectorApi.departmentTree,
  dropdownStyle: { maxHeight: '280px' },
  treeLine: true,
  treeNodeFilterProp: 'label',
  showSearch: true,
  treeCheckable: true,
  maxTagCount: 3,
  parentValue: unref(parentValue),
  maxTagPlaceholder: values => `+${values?.length} ...`
}))
</script>
