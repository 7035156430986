import { request } from '@/utils/request'
export const financeApi = {
  // 入职列表，按客户下载
  exportEntryList: data => request.get('finance/entries/export', data),
  // 入职列表-企业下拉
  getEntryCompanyList: data => request.get('finance/company/selector/entries', data),
  // 过保列表-企业下拉
  getOverInsuresCompanyList: data => request.get('finance/company/selector/overInsures', data),
  // 可结算列表-企业下拉
  getSettleCompanyList: data => request.get('finance/company/selector/settlements', data),
  // 发票列表-企业下拉
  getInvoiceCompanyList: data => request.get('finance/company/selector/invoices', data),
  // 回款列表-企业下拉
  getRefundCompanyList: data => request.get('finance/company/selector/refunds', data),
  // 入职列表-职位下拉
  getEntryJobList: data => request.get('settlementSelector/entries', data),
  // 过保列表-职位下拉
  getOverInsuresJobList: data => request.get('settlementSelector/overInsures', data),
  // 可结算列表-职位下拉
  getSettleJobList: data => request.get('finance/job/selector/settlements', data),
  // 发票列表-职位下拉
  getInvoiceJobList: data => request.get('finance/job/selector/invoices', data),
  // 回款列表-职位下拉
  getRefundJobList: data => request.get('settlementSelector/settlements', data),
  // 入职列表
  getEntries: data => request.get('supplierFinance/entries', data),
  // 过保列表
  getOverInsures: data => request.get('supplierFinance/over_insures', data),
  // 可结算列表
  getCanSettlementList: data => request.get('finance/settlement/follows', data),
  // 已开票列表
  getInvoiceList: data => request.get('finance/invoice/follows', data),
  // 已回款列表
  getRefundList: data => request.get('supplierFinance/settlements', data),
  // 上传erp文件
  uploadErpFile: data => request.post('file/erp', data),
  // 获取收款银行
  getRefundBankList: data => request.get('merchant/bank/erp', data),
  // 新增回款
  storeRefund: data => request.post('refunds', data),
  // 回款撤销
  cancelRefund: (id, data) => request.post(`refunds/${id}/cancel`, data),
  // 回款详情
  getRefundDetail: id => request.get(`refunds/${id}`),
  // 回款列表
  getRefundsList: data => request.get('refunds', data),

  // 撤销结算
  cancelApplySettlement: (id, data?:any) => request.post(`approval/${id}/cancel`, data),
  cancelApplySettlementBill: (id, data?:any) => request.post(`finance/settlements/${id}/cancel`, data),
  // 结算详情
  getSettlementsDetail: id => request.get(`finance/settlements/${id}`),
  // 自己负责的客户
  getCompanyCharge: data => request.get('company/charge', data),
  // 申请结算
  applyCanSettlement: data => request.post('finance/settlements', data),
  // 可结算列表
  getCanSettlement: data => request.get('follow/canSettlement', data),
  // 结算列表
  getSettlementList: data => request.get('finance/settlements', data),
}
