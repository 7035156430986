<template>
  <div>
    <a-tabs
      v-model:activeKey="activeTab"
      @change="tabChange"
    >
      <a-tab-pane
        v-for="item in tabItems"
        :key="item[props.valueField]"
        :tab="item[props.labelField]"
      />
    </a-tabs>
  </div>
</template>
<script lang="ts" setup>
import { PropType } from 'vue'
import { isFunction } from 'lodash-es'
const props = defineProps({
  items: {
    type: Array as PropType<Recordable[]>,
    default: () => ([])
  },
  getItems: {
    type: Function as PropType<()=>Recordable[]>,
    default: undefined
  },
  labelField: {
    type: String,
    default: 'label'
  },
  valueField: {
    type: String,
    default: 'value'
  },
  defaultValue: {
    type: [Number, String],
    default: ''
  },
  searchField: {
    type: String,
    default: ''
  }
})
type EmitEvents = {
  (e:'change', ...value):void
  (e:'changeTab', ...value):void
}
const emits = defineEmits<EmitEvents>()
const tabItems = computed(() => {
  if (isFunction(props.getItems)) {
    return props.getItems()
  } else {
    return props.items?.map(item => ({
      label: item[props.labelField],
      value: item[props.valueField]
    }))
  }
})
const activeTab = ref(props.defaultValue)
const tabChange = key => {
  let params: Recordable = {
    activeTab: key
  }
  if (props.searchField) {
    params[props.searchField] = key
  }
  emits('change', params)
  emits('changeTab', params)
}
</script>
