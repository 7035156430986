import { error, ready, runtime, env, config } from 'dingtalk-jsapi'
import { globalApi } from '@/api/global'
import { useEnv } from '@/hooks/env'

export const generateCode = () => new Promise<string>((resolve, reject) => {
  if (env.platform !== 'notInDingTalk') {
    ready(() => {
      // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
      runtime.permission.requestAuthCode({
        corpId: useEnv.ddCropID,
        onSuccess(res:{code:string}) {
          // 调用成功时回调
          // alert("钉钉登录")
          resolve(res.code)
        },
        onFail(err:any) {
          // 调用失败时回调
          console.log(err)
        }
      } as any)
    })
  } else {
    reject('非钉钉环境')
  }
})
export const isInDingTalk = () => env.platform !== 'notInDingTalk'

// 配置钉钉jsapi鉴权
export const configDingJssdk = async (url:string) => {
  if (isInDingTalk()) {
    const jsApiList = ['biz.chat.openSingleChat', 'biz.chat.chooseConversationByCorpId', 'biz.chat.toConversationByOpenConversationId']
    const signature = await globalApi.getDingJssdkSignature(url)
    config({
      ...signature,
      type: 0,
      jsApiList,
    })
    error(err => {
    // alert(`dd error: ${JSON.stringify(err)}`)
    })// 该方法必须带上，用来捕获鉴权出现的异常信息，否则不方便排查出现的问题
    ready(() => {
    })
  }
}