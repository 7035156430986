import { ExtractPropTypes } from 'vue'
import type { InputNumberProps } from 'ant-design-vue/es/input-number'

export const inputRangeProps = {
  // 双向数据绑定的该组件的值
  value: {
    type: null,
  },
  placeholder: {
    type: String,
  },
  min: {
    type: Number,
  },
  max: {
    type: Number,
  },
  addonAfter: {
    type: String
  },
  addonBefore: {
    type: String
  },
  middleText: {
    type: String,
    default: '至'
  }
}

export type InputRangeProps = Partial<ExtractPropTypes<typeof inputRangeProps>> & InputNumberProps