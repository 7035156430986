<template>
  <span>
    <quick-date-picker
      :class="selectClass"
      :style="selectStyle"
      :value="searchState.date"
      :reference-key="referenceKey"
      @change="handleChangeQuickDate"
    />

    <a-range-picker
      v-if="searchState.date === 'custom'"
      v-model:value="range"
      :allow-clear="false"
      input-read-only
      class="ml-2 w-[260px]"
      value-format="YYYY-MM-DD"
      @change="changeRange"
    />
  </span>
</template>

<script lang="ts" setup>
import { PropType, reactive } from 'vue'
import QuickDatePicker from '@/components/form/components/QuickDatePicker.vue'

const range = ref([])
const emits = defineEmits(['changeDateFilter'])
const props = defineProps({
  defaultDate: {
    type: String as PropType<string>,
    default: 'day',
  },
  selectStyle: {
    type: Object,
    default: () => ({})
  },
  selectClass: {
    type: String,
    default: 'w-[200px]'
  },
  referenceKey: {
    type: String as PropType<Reference.ReferenceKey>,
    default: () => 'dateSelectTypes'
  },
})

const searchState = reactive<{ date: string, range:string[] }>({
  date: props.defaultDate,
  range: [],
})


function changeRange() {
  searchState.range = range.value
  emits('changeDateFilter', searchState)
}
watch(
  () => [searchState.date],
  () => {
    if (searchState.date === 'custom' && searchState.range.length === 0) {
      return
    }
    emits('changeDateFilter', searchState)
  },
  {
    immediate: true,
  },
)

function handleChangeQuickDate(value) {
  searchState.date = value
}
</script>