<template>
  <div
    class="flex flex-wrap items-center justify-center grid-table-actions"
    @click.stop="handleCellClick"
  >
    <template v-if="getDrowdowns.length === 1 && !rowActionsShowIcon">
      <template v-for="action in getActions">
        <a-popconfirm
          v-if="action.confirm"
          :key="action.label"
          :on-confirm="() => handleAction(action)"
          :title="action.confirm"
          placement="left"
          size="small"
        >
          <a-button
            v-bind="omit(action,'bIcon')"
          >
            <a-tooltip
              v-if="action.bIcon"
              :title="action.label"
            >
              <div>
                <basic-icon
                  v-bind="action.bIcon"
                />
              </div>
            </a-tooltip>
            <span v-else>
              {{
                action.label
              }}
            </span>
          </a-button>
        </a-popconfirm>
        <a-button
          v-else
          :key="action.confirm + action.label"
          size="small"
          v-bind="omit(action,'bIcon')"
          @click.prevent="() => handleAction(action)"
        >
          <a-tooltip
            v-if="action.bIcon"
            :title="action.label"
          >
            <basic-icon
              v-bind="action.bIcon"
            />
          </a-tooltip>

          <span v-else>
            {{
              action.label
            }}
          </span>
        </a-button>
      <!-- <a-divider v-if="index !== getActions.length - 1" type="vertical" style="margin: 0 2px" /> -->
      </template>
    </template>
    <a-dropdown
      v-else-if="getDrowdowns.length > 1 || (rowActionsShowIcon && getDrowdowns.length === 1)"
      :trigger="['click']"
    >
      <a-tooltip title="操作按钮">
        <a-button
          type="link"
          size="small"
        >
          <template #icon>
            <basic-icon
              name="icon-gengduo1"
              class="text-[22px] "
            />
          </template>
        </a-button>
      </a-tooltip>
      <template #overlay>
        <a-menu>
          <a-menu-item
            v-for="dropdown in getDrowdowns"
            :key="dropdown.confirm + dropdown.label"
          >
            <a-popconfirm
              v-if="dropdown.confirm"
              :on-confirm="() => handleAction(dropdown)"
              :title="dropdown.confirm"
              placement="left"
            >
              {{ dropdown.label }}
            </a-popconfirm>
            <span
              v-else
              @click.prevent="() => handleAction(dropdown)"
            >
              {{ dropdown.label }}
            </span>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType, unref, toRaw } from 'vue'
import { isFunction, omit } from 'lodash-es'
import { useUserStore } from '@/store'
import { useTableContext } from '../hooks/context'

const context = useTableContext()
const props = defineProps({
  actions: {
    type: Array as PropType<IGridTable.RowAction[]>,
    default: () => [],
  },
  record: {
    type: Object as PropType<any>,
    required: true,
  },
  rowActionsShowIcon: { // 全部显示icon图标
    type: Boolean,
    required: false,
  },
})

const getActions = computed((): IGridTable.RowAction[] => {
  const actions = unref(props.actions).filter(a => {
    if (a.isShow !== undefined) {
      return isFunction(a.isShow) ? a.isShow(props.record) : a.isShow
    }
    if (!a.requiredPermission) {
      return true
    }
    return useUserStore().hasPermission(a.requiredPermission)
  })
  const endIndex = actions.length <= 3 ? 3 : 2
  return actions.slice(0, endIndex).map(action => ({
    type: 'link',
    size: 'small',
    ...action,
  }))
})

const getDrowdowns = computed((): IGridTable.RowAction[] => {
  const actions = unref(props.actions).filter(a => {
    if (a.isShow !== undefined) {
      return isFunction(a.isShow) ? a.isShow(props.record) : a.isShow
    } else {
      return true
    }
  })
  // if (actions.length <= 3) {
  //   return []
  // }
  // return actions.slice(2).map(action => ({
  //   type: 'link',
  //   size: 'small',
  //   ...action,
  // }))
  return actions.map(action => ({
    type: 'link',
    size: 'small',
    ...action,
  }))
})


async function handleAction(action: IGridTable.RowAction) {
  await action.click?.(toRaw(props.record))
  action.needReload && context.reload()
}
</script>
