<template>
  <a-cascader v-bind="bindAttrs" />
</template>
<script lang="ts" setup>
import { regionsTree } from '@/utils/region'

type EmitEvents = {
  (e: 'change', ...value): void,
  (e: 'update:value', ...value): void,
  (e: 'options-change', options:Recordable[]):void
}
const emits = defineEmits<EmitEvents>()
const attrs = useAttrs()


const bindAttrs = computed(() => ({
  options: regionsTree,
  showSearch: true,
  onChange: (...newValue) => emits('change', ...newValue),
  'onUpdate:value': (...newValue) => emits('update:value', ...newValue),
  ...attrs
}))

</script>