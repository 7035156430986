<template>
  <a-select
    :options="options"
    v-bind="$attrs"
  />
</template>
<script lang="ts" setup>
import { useAppStore } from '@/store'
import { roleSelectProps } from './props'
const props = defineProps(roleSelectProps)
const { reference } = useAppStore()
const options = computed(() => reference?.supplierRoles?.map(item => ({
  ...item,
  label: item.name,
  value: item?.pivot?.role_id ?? item.id
})))
</script>
