import { Tooltip, Tag } from 'ant-design-vue/es'
import BasicIcon from '@/components/icon/basic-icon.vue'
import { get, isFunction } from 'lodash-es'
export const getMoreTagColumn = (title: string, dataIndex: string, listField:string, mapField:string, clickTagFn?:(record:any)=>{}) => {
  const getNames = (record): string[] => {
    let list:any = get(record, listField) ?? []
    let names: string[] = []
    if (Array.isArray(list)) {
      if (!mapField) {
        names = list
      } else {
        names = list.map(item => get(item, mapField))
      }
    }
    if (Array.isArray(names) && names?.length) {
      return names
    }
    return []
  }
  return ({
    title,
    dataIndex,
    exportRender: ({ record }) => {
      let names = getNames(record)
      if (names.length) {
        return names.join(',')
      }
      return '--'
    },
    customRender: ({ record }) => {
      let names = getNames(record)
      if (names.length) {
        return (
          <div class={'flex items-center'}>
            <div onClick={() => {
              isFunction(clickTagFn) && clickTagFn(record)
            }}>
              <Tag class="mb-[4px]">{names[0]}</Tag>
            </div>
            <Tooltip
              placement="top"
              title={names.map((item, index) => (
                <div onClick={() => {
                  isFunction(clickTagFn) && clickTagFn(record)
                }}>
                  <Tag
                    class={[
                      index !== 0 ? 'mt-[4px]' : '',
                      'cursor-pointer mr-0',
                    ]}
                  >
                    {item}
                  </Tag>
                </div>
              ))}
            >
              {names.length > 1 && (
                <BasicIcon
                  class="text-[16px] text-primaryColor-default"
                  name="icon-gengduo"
                />
              )}
            </Tooltip>
          </div>
        )
      }
      return '--'
    },
  })
}