import { request } from '@/utils/request'

/** 用户接口 */
const userApi = {
  // 通过id获取用户信息
  getUserById: id => request.get('user/detail', { user_id: id }),
  // 添加用户
  addUser: params => request.post('users', params),
  // 用户邮箱
  getEmails: params => request.get('user/emails', params),
  updateUserInfo: params => request.post('my/update-info', params),
  // 修改密码
  resetPassword: params => request.post(`supplierUsers/${params.id}/editPassword`, params),
  // 新增邮箱
  storeEmail: params => request.post('my/store-email', params),
  // 邮箱列表
  emails: params => request.get('my/emails', params),
  updateAvatar: params => request.post('user/update-avatar', params),
  // 删除邮箱
  delEmail: id => request.post('my/destroy-email', { id }),
}

export { userApi }