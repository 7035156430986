<template>
  <api-tree-select
    v-bind="$attrs"
    :api="getDeptTree"
    :api-params="apiParams ?? {}"
  />
</template>
<script lang="ts" setup>
import { deptTreeSelectProps } from './props'
import { useAppStore } from '@/store'
import { cloneDeep } from 'lodash-es'
const { apiParams } = defineProps(deptTreeSelectProps)
const { reference } = useAppStore()
const getDeptTree = () => (new Promise((resolve, reject) => {
  if (reference) {
    let deptTree = cloneDeep(reference.deptTree)
    resolve(deptTree)
  } else {
    reject([])
  }
}))
</script>
