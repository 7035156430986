<style scoped>
:deep(.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab) {
  padding: 0 !important;
  padding-right: 10px;
}

:deep(.ant-tabs-tab-remove) {
  margin: 0;
  padding: 0;
  padding-right: 5px;
}

</style>
<style lang='less'>
.ant-tabs-dropdown-menu-item > span {
  display: flex !important;
  justify-content: space-between;
}

.ant-tabs-tab {
  border-radius: 8px !important;
}

.history-tab-container {
  .ant-tabs {
    justify-content: center;
  }

  .ant-tabs-nav {
    margin: 0 !important;
  }

  .ant-tabs-nav-list {
    align-items: center;
  }

  .ant-tabs-tab {
    height: 26px;

    .ant-tabs-tab-btn,
    button {
      color: #999999;
    }
  }

  .ant-tabs-content-holder {
    display: none;
  }

  .ant-tabs-nav-more {
    font-size: 18px;
    color: #FFFFFF;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom-color: #F0F0F0 !important;

    .ant-tabs-tab-btn,
    button {
      font-weight: bold;
      color: #1373C5;
    }
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    padding: 0;
    padding-right: 12px;
  }

  .ant-tabs-tab-remove {
    margin-left: 0;

    &:hover {
      transition: all 0.1s;
      transform: scale(1.2);
    }
  }
}

.history-tab-container .ant-tabs-nav::before {
  border-bottom: none !important;
  box-shadow: #1373C5;
}
</style>

<template>
  <div class="history-tab-container">
    <div
      v-if="isShowTabMenu"
      style="z-index: 999999;"
      class="absolute top-0 left-0 w-screen h-screen mask "
      @click="isShowTabMenu = false"
      @contextmenu.prevent="isShowTabMenu = false"
    />
    <div class="flex justify-between  h-[40px]">
      <div
        class="w-[200px] flex justify-center items-center flex-shrink-0"
        :class="{ }"
      >
        <img
          class="w-[128px] h-[40px]"
          src="@images/logo2.png"
        >
        <!-- <span class="text-xl text-white">欢创招聘</span> -->
      </div>
      <a-tabs
        :active-key="activeKey"
        hide-add
        type="editable-card"
        class="flex-1"
        @edit="onEdit"
        @change="clickPage"
      >
        <a-tab-pane
          v-for="(page, pageIndex) in historyPageMsg"
          :key="page.url"
          :closable="historyPageMsg.length !== 1"
        >
          <template #tab>
            <div
              class="pl-[12px] py-[8px]"
              :class="{'pr-[12px]': historyPageMsg.length === 1}"
              @contextmenu.prevent="clickTab($event, page, pageIndex)"
            >
              {{ page.title }}
            </div>
          </template>
        </a-tab-pane>
      </a-tabs>
      <div class="flex items-center mr-[10px]">
        <a-tooltip>
          <template #title>
            返回上一页
          </template>
          <div
            class="bg-white cursor-pointer h-[26px] w-[26px] rounded flex justify-center items-center"
            @click="router.go(-1)"
          >
            <arrow-left-outlined />
          </div>
        </a-tooltip>

        <a-tooltip>
          <template #title>
            刷新页面
          </template>
          <div
            class="bg-white cursor-pointer h-[26px] w-[26px]  rounded flex justify-center items-center ml-[10px]"
            @click="refreshPage"
          >
            <redo-outlined />
          </div>
        </a-tooltip>

        <a-tooltip>
          <template #title>
            标签菜单
          </template>
          <div
            class="bg-white cursor-pointer h-[26px] w-[26px]  rounded flex justify-center items-center ml-[10px]"
            @click="clickTab($event,undefined,undefined,true)"
          >
            <unordered-list-outlined />
          </div>
        </a-tooltip>
        <template v-if="userStore.user?.supplier?.name">
          <div class="flex items-center justify-center w-[30px] ">
            <div class="bg-gray-300 h-[30px] mt-[0px] w-[1px]" />
          </div>
          <span class="text-white">{{ userStore.user?.supplier?.name }}</span>
        </template>
        <div class="flex items-center justify-center w-[30px] ">
          <div class="bg-gray-300 h-[30px] mt-[0px] w-[1px]" />
        </div>
        <nav-bar />
      </div>
    </div>
    <div
      v-if="isShowTabMenu"
      :style="tabMenuStyle"
      class="bg-white rounded-md shadow-md tabMenu"
    >
      <div
        v-for="i in tabMenuContext"
        :key="i.text"
        class="flex items-center w-40 h-8 pl-2 leading-8 rounded-md border-1 hover:bg-primaryColor-default hover:text-white"
        :class="[i.isAble ? 'cursor-pointer' : 'cursor-not-allowed']"
        @click="i.isAble ? clickTabMenuItem(i.text) : ''"
      >
        <component
          :is="i.icon ? antIcons[i.icon] : BasicIcon"
          class="mr-2"
          :class="{ 'text-gray-200': !i.isAble }"
          :name="i.icon ? '' : i.iconfont"
        />
        <span :class="{ 'text-gray-200': !i.isAble }">{{ i.text }}</span>
      </div>
    </div>
    <slot />
  </div>
</template>
<script lang='ts' setup>
import NavBar from './index.vue'
import { isEmpty, isUndefined } from 'lodash-es'
import { CSSProperties, inject } from 'vue'
import * as antIcons from '@ant-design/icons-vue'
import BasicIcon from '@/components/icon/basic-icon.vue'
import { Key } from 'ant-design-vue/es/_util/type'
import { listenerRouteChange } from '@/mitt/route-listener'
import { RouteLocationNormalized } from 'vue-router'
import { HISTORY_PAGE } from '@/utils/storage'
import { useUserStore } from '@/store'

const userStore = useUserStore()
const refresh = inject('refresh', Function, true)
const router = useRouter()
const emit = defineEmits<
  {(event: 'pageChange', activePage:HistoryPageMsg, route:RouteLocationNormalized),
  (event: 'afterCloseAll'): void,
  (event: 'afterClose', closeRouteName: string | string[]): void,
}>()
type HistoryPageMsg = { type: 'name' | 'path', url: string, urlParams: Record<string, any>, name: string, isActive: boolean, title: string }
const historyPageMsg = ref([]) as Ref<HistoryPageMsg[]>
const activeKey = ref('')
// 监听路由的改变(监听路由比较耗费性能，弃用)
// watch(route, (newRoute, oldRoute) => {
//   const { meta, path, name, params, query } = newRoute
//   let currentRoute = {} as HistoryPageMsg
//   let hasPage = false
//   historyPageMsg.value.forEach(page => {
//     page.isActive = false
//     if ((path === page.url) || (name === page.url)) {
//       hasPage = true
//       page.isActive = true
//     }
//   })
//   historyPageMsg.value.forEach(i => {
//     i.isActive && (activeKey.value = i.url)
//   })
//   // 不是新的页面直接结束
//   if (hasPage) {
//     return
//   }
//   if (!isEmpty(params)) {
//     // 如果有params参数，就都用name跳转
//     currentRoute.type = 'name'
//     currentRoute.url = name! as string
//     currentRoute.urlParams = params
//   } else if (!isEmpty(query)) {
//     // 如果有query参数，就都用path跳转
//     currentRoute.type = 'path'
//     currentRoute.url = path
//     currentRoute.urlParams = query
//   } else {
//     // 如果没有参数，直接使用path跳转
//     currentRoute.type = 'path'
//     currentRoute.url = path
//     currentRoute.urlParams = {}
//   }
//   currentRoute.isActive = true
//   currentRoute.title = meta.title || '未设置标题'
//   activeKey.value = currentRoute.url
//   historyPageMsg.value.push(currentRoute)

// }, { immediate: true })

onMounted(() => {

  /* 页面挂载好取出历史标签页 */
  localStorage.getItem(HISTORY_PAGE) && (historyPageMsg.value = JSON.parse(localStorage.getItem(HISTORY_PAGE)!))

  listenerRouteChange(route => {
    const { meta, path, name, params, query } = route

    if (meta.hideHistoryPage) { // 不记录历史页面的直接结束函数
      return
    }
    let currentRoute = {} as HistoryPageMsg
    let hasPage = false
    historyPageMsg.value.forEach(page => {
      page.isActive = false
      if ((path === page.url) || (name === page.url)) {
        hasPage = true
        page.isActive = true
        emit('pageChange', page, route)
        activeKey.value = page.url
      }
    })


    if (!isEmpty(params)) {
    // 如果有params参数，就都用name跳转
      currentRoute.type = 'name'
      currentRoute.url = name! as string
      currentRoute.urlParams = params
    } else if (!isEmpty(query)) {
    // 如果有query参数，就都用path跳转
      currentRoute.type = 'path'
      currentRoute.url = path
      currentRoute.urlParams = query
    } else {
    // 如果没有参数，直接使用path跳转
      currentRoute.type = 'path'
      currentRoute.url = path
      currentRoute.urlParams = {}
    }

    currentRoute.name = name as string // name存起来为了配合 keepAlive 在关闭历史标签的时候关闭缓存

    currentRoute.isActive = true
    currentRoute.title = meta.title || '未设置标题'
    activeKey.value = currentRoute.url
    // 不是新的页面直接结束
    if (hasPage) {
      const index = historyPageMsg.value.findIndex(page => page.name === currentRoute.name)
      historyPageMsg.value[index] = currentRoute
      return
    }
    historyPageMsg.value.push(currentRoute)
  })
})


// 刷新页面时存储历史标签信息防止刷新丢失
window.onbeforeunload = function() {
  localStorage.setItem(HISTORY_PAGE, JSON.stringify(historyPageMsg.value))
}

const refreshPage = () => {
  // router.go(0)
  refresh()
}

/* 点开右键的标签的信息 */
let activeRightMsg = {} as HistoryPageMsg
const isShowTabMenu = ref<boolean>(false)
const tabMenuStyle = ref<CSSProperties>({})
type MenuItemText = '重新加载' | '关闭标签页' | '关闭左侧标签页' | '关闭右侧标签页' | '关闭其他标签页' | '关闭全部标签页'
type IAntIconName = keyof typeof antIcons

// 标签菜单配置
const tabMenuContext = ref<{
  icon?: IAntIconName, iconfont?: string, text: MenuItemText,
  isAble: boolean
}[]>([
  {
    icon: 'CloseSquareOutlined',
    text: '关闭标签页',
    isAble: false
  },
  {
    icon: 'ArrowLeftOutlined',
    text: '关闭左侧标签页',
    isAble: false
  },
  {
    icon: 'ArrowRightOutlined',
    text: '关闭右侧标签页',
    isAble: false
  },
  {
    icon: 'SwapOutlined',
    text: '关闭其他标签页',
    isAble: false
  },
  {
    icon: 'StopOutlined',
    text: '关闭全部标签页',
    isAble: false
  },
])

const clickTabMenuItem = (type: MenuItemText) => {
  switch (type) {

    case '关闭标签页':
      onEdit(activeKey.value, 'remove')
      isShowTabMenu.value = false
      break
    case '关闭左侧标签页':
      closeBoth(activeRightMsg, 'left')
      isShowTabMenu.value = false
      break
    case '关闭右侧标签页':
      closeBoth(activeRightMsg, 'right')
      isShowTabMenu.value = false
      break
    case '关闭其他标签页':
      closeOther(activeRightMsg)
      isShowTabMenu.value = false
      break
    case '关闭全部标签页':
      closeAll()
      isShowTabMenu.value = false
      break
    default:
  }

}
const clickTab = (e: MouseEvent, page?: HistoryPageMsg, pageIndex?: number, fixedBtn?:boolean) => {

  if (page === undefined && pageIndex === undefined) {
    // 如果点击最右侧的菜单按钮，手动寻找当前激活的路由
    const findIndex = historyPageMsg.value.findIndex(item => item?.url === activeKey.value)
    if (findIndex !== -1) {
      pageIndex = findIndex
      page = historyPageMsg.value[findIndex]
    } else {
      return
    }
  }

  // 取消默认事件
  e.preventDefault()
  activeRightMsg = page!
  isShowTabMenu.value = !isShowTabMenu.value
  tabMenuStyle.value = {
    position: 'fixed',
    top: `${e.clientY}px`,
    left: `${e.clientX}px`,
    zIndex: 9999999,
    padding: '10px',
  }
  if (fixedBtn) {
    // 点击最右侧的按钮
    tabMenuStyle.value = {
      position: 'fixed',
      top: `${e.clientY + 30}px`,
      left: `${e.clientX - 160}px`,
      zIndex: 9999999,
      padding: '10px',
    }
  }
  tabMenuContext.value.forEach(i => {
    i.isAble = true
  })

  if (historyPageMsg.value.length === 1) {
    tabMenuContext.value.forEach(i => {
      i.isAble = false
    })
    return
  }
  if (historyPageMsg.value.length - 1 === pageIndex) {
    tabMenuContext.value.forEach(i => {
      (i.text === '关闭右侧标签页') && (i.isAble = false)
    })
    return
  }
  if (pageIndex === 0) {
    tabMenuContext.value.forEach(i => {
      (i.text === '关闭左侧标签页') && (i.isAble = false)
    })

  }
}


const clickPage = (targetKey?: string | MouseEvent | Key, page?: HistoryPageMsg) => {

  const pageGuard = (page: HistoryPageMsg | undefined): page is HistoryPageMsg => !isUndefined(page)
  isUndefined(page) && historyPageMsg.value.forEach(i => {
    if (i.url === targetKey) {
      page = i
    }
  })

  if (pageGuard(page)) { // 类型守卫判断减少后面page的类型断言

    // 没有参数直接path跳转
    if (isEmpty(page.urlParams)) {
      router.push(page.url)
      return
    }
    // 如果有路由参数并且使用的是path跳转
    if (!isEmpty(page.urlParams) && page.type === 'path') {
      // 使用query传参
      router.push({
        path: page.url,
        query: page.urlParams
      })
    }
    // 如果有路由参数并且使用的是name跳转
    if (!isEmpty(page.urlParams) && page.type === 'name') {
      // 使用params传参
      router.push({
        name: page.url,
        params: page.urlParams
      })
    }
  }
}
// 获取要被销毁的页面name组成的数组
const getDestoryPages = getCondition => {
  const destoryPages:string[] = []
  historyPageMsg.value.forEach((pageItem, i) => {
    if (getCondition(pageItem, i)) {
      destoryPages.push(pageItem.name)
    }
  })
  return destoryPages
}
const closeBoth = (page: HistoryPageMsg, type: 'right' | 'left') => {
  historyPageMsg.value.forEach((pageItem: HistoryPageMsg, index) => {
    if (pageItem.url === page.url && type === 'right') {
      emit('afterClose', getDestoryPages((pageItem: HistoryPageMsg, i:number) => i > index))
      historyPageMsg.value = historyPageMsg.value.filter((pageItem: HistoryPageMsg, i) => i <= index)
    }
    if (pageItem.url === page.url && type === 'left') {
      emit('afterClose', getDestoryPages((pageItem: HistoryPageMsg, i:number) => i < index))
      historyPageMsg.value = historyPageMsg.value.filter((pageItem: HistoryPageMsg, i) => i >= index)
    }
  })

  /* 避免激活标签页被关掉还停留在之前的标签页 ,在右键选中标签不是激活时跳转路由*/
  if (!page.isActive && type === 'right') {
    clickPage(activeKey.value, historyPageMsg.value.at(-1)!)
  }
  if (!page.isActive && type === 'left') {
    clickPage(activeKey.value, historyPageMsg.value[0])
  }

}
const closeOther = (page: HistoryPageMsg) => {
  emit('afterClose', getDestoryPages((pageItem: HistoryPageMsg) => pageItem.url !== page.url))

  historyPageMsg.value = historyPageMsg.value.filter((pageItem: HistoryPageMsg, index) => pageItem.url === page.url)
  clickPage(activeKey.value, historyPageMsg.value[0])
}
const closeAll = () => {
  historyPageMsg.value.length = 0
  emit('afterCloseAll')
  router.push({ name: 'home' })
}

const onEdit = (targetKey: MouseEvent | Key | KeyboardEvent, action: 'add' | 'remove') => {
  if (action === 'remove') {
    let page = {} as HistoryPageMsg
    historyPageMsg.value.forEach(i => {
      if (i.url === targetKey) {
        page = i
      }
    })
    // 如果关闭的是当前页面且不是第一个页面-->去到上个页面否则去下个页面
    if (page.isActive) {
      historyPageMsg.value.forEach((pageItem: HistoryPageMsg, index) => {
        if ((pageItem.url === page.url) && index !== 0) {
          // 将找出来的索引的前一个页面激活并跳转
          clickPage(activeKey.value, historyPageMsg.value[index - 1])
        } else if ((pageItem.url === page.url) && index === 0) {
          // 将找出来的索引的下一个页面激活并跳转
          clickPage(activeKey.value, historyPageMsg.value[index + 1])
        }
      })
    }

    /*
  过滤出剩下的页面
  过滤条件:路由参数和跳转路径都不相等的页面过滤出来

  */
    // const destoryPages:string[] = []
    // historyPageMsg.value.forEach(pageItem => {
    //   if (((pageItem.urlParams === page.urlParams) && (pageItem.url === page.url))) {

    //     destoryPages.push(pageItem.name)
    //   }
    // })
    historyPageMsg.value = historyPageMsg.value.filter((pageItem: HistoryPageMsg) => ((pageItem.urlParams !== page.urlParams) && (pageItem.url !== page.url)))
    emit('afterClose', page.name) // todo 同时关闭多个页面的情况下还没触发关闭事件
  } else {
    // add(targetKey as string)
  }
}
const reset = () => {
  historyPageMsg.value = []
}
defineExpose({ reset })
</script>