<script lang="tsx">
import { get } from 'lodash-es'
import { useAppStore } from '@/store'
import { referenceTagProps } from './props'
export default defineComponent({
  props: referenceTagProps,
  setup(props, context) {
    const { getReferenceItemsValueMap } = useAppStore()
    const { referenceKey, model, dataIndex, value } = props
    function getValue() {
      if (!referenceKey) {
        return '--'
      }
      const enumValueMap = getReferenceItemsValueMap(referenceKey)
      const val = value ?? get(model, dataIndex)
      return <div class="custom-tag text-[14px]" style={{
        color: enumValueMap?.[val]?.color,
        backgroundColor: enumValueMap?.[val]?.bgColor
      }}>{enumValueMap?.[val]?.label}</div>
    }
    return () => (
      <div>
        {
          getValue()
        }
      </div>
    )
  }
})
</script>
