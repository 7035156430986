
import validator from 'validator'
import axios from 'axios'
import {
  getItem
} from '@/utils/storage'
import { getSuffix } from './qs'
export function urlToBase64(url: string, mineType?: string): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!validator.isURL(url)) {
      resolve(url)
      return
    }
    let canvas = document.createElement('CANVAS') as HTMLCanvasElement | null
    const ctx = canvas!.getContext('2d')

    const img = new Image()
    img.crossOrigin = ''
    img.onload = function() {
      if (!canvas || !ctx) {
        return reject()
      }
      canvas.height = img.height
      canvas.width = img.width
      ctx.drawImage(img, 0, 0)
      const dataURL = canvas.toDataURL(mineType || 'image/png')
      canvas = null
      resolve(dataURL)
    }
    img.src = url
  })
}

export const getFileSize = (value: number) => {
  if (!value) {
    return '0 Bytes'
  }
  const unitArr = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let index = 0
  const srcSize = parseFloat(value.toString())
  index = Math.floor(Math.log(srcSize) / Math.log(1024))
  const size = srcSize / Math.pow(1024, index)
  const rs = size.toFixed(2) // 保留的小数位数
  return rs + unitArr[index]
}


export function download(url: string, data: any, filename: string) {
  let baseApiUrl = import.meta.env.VITE_APP_URL
  return new Promise((resolve, reject) => {
    const token = getItem('token')
    axios({
      baseURL: baseApiUrl,
      timeout: 0,
      url,
      method: 'get',
      params: data,
      responseType: 'blob',
      headers: {
        Platform: 'pc',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json;charset=utf-8'
      }
    }).then((res: any) => {
      const stream: any = res.data // 后端用stream返回Excel文件
      const blob: any = new Blob([stream])
      const downloadElement = document.createElement('a')
      const href = window.URL.createObjectURL(blob) // 创建下载的链接
      downloadElement.href = href
      downloadElement.download = filename // 下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 点击下载
      document.body.removeChild(downloadElement) // 下载完成移除元素
      window.URL.revokeObjectURL(href) // 释放掉blob对象
      resolve(null)
    })
      .catch(() => {
        reject()
      })
  })
}


/**
 * 异步文件下载
 * @getListApiParams {string} url 获取文件对应的URL
 * @getListApiParams {object} params 接口携带参数
 * @getListApiParams {string} fileName 文件名
 * @getListApiParams {string} fileType 文件类型
 */
export function downloadFile(url, params = {}, fileName, fileType = '') {
  params = Object.assign({}, params)

  axios({
    url,
    params,
    method: 'get',
    responseType: 'blob',
  }).then(res => {
    const fileBlob = new Blob([res.data]) // 创建一个Blob对象
    const a = document.createElement('a')
    a.download = `${fileName}${fileType ? `.${fileType}` : ''}`
    a.href = URL.createObjectURL(fileBlob)
    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
    a.remove()
  })
}

export function downloadFile2(url:string, data:any) {
  const link = document.createElement('a')
  link.setAttribute('download', data)
  link.setAttribute('href', url)
  link.click()
}

export function downloadFileDirect(url, params = {}, fileName, fileType) {
  params = Object.assign({}, params)
  axios({
    url,
    params,
    method: 'get',
    responseType: 'blob',
  }).then(res => {
    const fileBlob = new Blob([res.data]) // 创建一个Blob对象
    const a = document.createElement('a')
    a.download = `${fileName}.${fileType}`
    a.href = URL.createObjectURL(fileBlob)
    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
    a.remove()
  })
}

// 相关资源 https://juejin.cn/post/6844903561017425927
export function previewFromUrl(fileUrl: string) {
  function isWordExcelOrPPT(url) {
    // https://zhidao.baidu.com/question/561340173.html
    const keySuffixes = [
      'doc',
      'docx',
      'docm',
      'dotx',
      'dotm',
      'xls',
      'xlsx',
      'xlsm',
      'xltx',
      'xltm',
      'xlsb',
      'xlam',
      'ppt',
      'pptx',
      'pptm',
      'ppsx',
      'ppsm',
      'potx',
      'potm',
      'ppam',
    ]
    const fileSuffix = getSuffix(url)
    return keySuffixes.includes(fileSuffix)
  }

  if (isWordExcelOrPPT(fileUrl)) {
    return window.open(`https://view.officeapps.live.com/op/view.aspx?src=${fileUrl}`)
  }
  return window.open(fileUrl)
}

// 根据url获取文件拓展名
export function getExtnameByUrl(url:string) {
  if (!url) {
    return ''
  }
  return url.substring(url.lastIndexOf('.') + 1)
}

// 根据url判断是否是图片链接
export function isImgUrl(url: string) {
  const extname = getExtnameByUrl(url)
  const picExtnames = ['jpg', 'jpeg', 'png']
  return picExtnames.includes(extname)
}