<template>
  <a-select
    v-bind="bindAttrs"
    @dropdownVisibleChange="handleFetch"
  >
    <template
      v-if="loading"
      #suffixIcon
    >
      <loading-outlined spin />
    </template>
    <template
      v-if="loading"
      #notFoundContent
    >
      <span>
        <loading-outlined
          spin
          class="mr-1"
        />
        暂无数据
      </span>
    </template>
  </a-select>
</template>
<script lang="ts" setup>
import { computed, unref, useAttrs } from 'vue'
import { get, isFunction, isArray } from 'lodash-es'
import { getCacheApi } from './api-cache'
import { customApiSelectProps } from './props'

const props = defineProps(customApiSelectProps)
type EmitEvents = {
  (e:'change', ...value):void
  (e:'update:value', ...value):void
  (e:'options-change', options:Recordable[]):void
  (e:'OptionsSelect', options:Recordable[]):void
}
const emits = defineEmits<EmitEvents>()
const attrs = useAttrs()
const loading = ref(false)
const isFetched = ref(false)
const options = ref<Recordable[]>([])
const rawOptions = ref<Recordable[]>([])
const value = computed(() => (props.value === 0 && props.zeroToUndefined ? undefined : props.value))

onMounted(() => {
  props.immediate && fetch()
})

async function handleFetch() {
  if (!isFetched.value) {
    await fetch()
  }
}

async function fetch() {
  const { api, apiParams, resultField, labelField, valueField, numberToString } = props
  if (!api || !isFunction(api)) {
    return
  }

  loading.value = true
  const res = await getCacheApi(api, props.needPagination ? Object.assign(props.pagination, apiParams) : apiParams)
  let optionData = isArray(res) ? res : get(res, resultField)
  rawOptions.value = optionData
  options.value = optionData?.map(item => {
    const optionValue = item[valueField]
    return {
      label: isFunction(labelField) ? labelField(item) : item[labelField as string],
      value: numberToString ? `${optionValue}` : optionValue,
      origin: item
    }
  })
  isFetched.value = true
  emits('options-change', options.value)
  loading.value = false

}

const bindAttrs = computed(() => ({
  dropdownStyle: { maxHeight: '280px' },
  optionFilterProp: 'label',
  showSearch: true,
  value: unref(value),
  options: unref(options),
  onChange: (...newValue) => {
    emits('change', ...newValue)
    if (isFunction(props.onRawOptionSelect) && Array.isArray(newValue) && Array.isArray(rawOptions.value)) {
      if (Array.isArray(newValue[0])) {
        const chooseRawOptions = rawOptions.value?.filter(item => newValue[0].includes(item[props.valueField]))
        if (chooseRawOptions) {
          props.onRawOptionSelect(chooseRawOptions)
        }
      } else {
        const chooseRawOptions = rawOptions.value?.find(item => item[props.valueField] == newValue[0])
        if (chooseRawOptions) {
          props.onRawOptionSelect(chooseRawOptions)
        }
      }

    }
  },
  'onUpdate:value': (...newValue) => emits('update:value', ...newValue),
  ...attrs,
}))
</script>
