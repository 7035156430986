import { request } from '@/utils/request'

/** 下拉框选项接口 */
const selectorApi = {
  user: params => request.get('common/selector/user', params),
  company: () => request.get('common/selector/company'),
  job: params => request.get('common/selector/job', params),
  email: () => request.get('common/selector/email'),
  department: () => request.get('common/selector/department'),
  departmentTree: () => request.get('common/selector/departmentTree'),
}
export { selectorApi }